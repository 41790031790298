import Qs from "qs";

export default function user({ next, store }) {
  if (window.location.pathname !== "/sign-in") {
    localStorage.setItem("fromRoute", window.location.pathname);
    const searchObject = Qs.parse(window.location.search.substring(1), {
      arrayFormat: "repeat",
      encode: false,
    });
    localStorage.setItem("fromRouteQuery", JSON.stringify(searchObject));
  }
  if (!store.getters["auth/isAuthenticated"]) {
    return next({
      name: "LoginPage",
    });
  }
  return next();
}
