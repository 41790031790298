export const FAILED_AUTHENTICATION = "FAILED_AUTHENTICATION";
export const NEW_CREATED_SOURCE = "NEW_CREATED_SOURCE";
export const NEW_CREATED_CHANNEL = "NEW_CREATED_CHANNEL";
export const NEW_CREATED_TAG = "NEW_CREATED_TAG";
export const CHANNEL_DELETED = "CHANNEL_DELETED";
export const NEW_CREATED_PROXY_PACKAGE = "NEW_CREATED_PROXY_PACKAGE";
export const NEW_CREATED_PROXY = "NEW_CREATED_PROXY";
export const NEW_IMPORTED_PROXIES = "NEW_IMPORTED_PROXIES";
export const NEW_CREATED_ARTICLE = "NEW_CREATED_ARTICLE";
export const NEW_CREATED_TASK = "NEW_CREATED_TASK";
export const UPDATED_ACCOUNT_PACKAGE = "UPDATED_ACCOUNT_PACKAGE";
