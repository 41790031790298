import apiCall from "@/utils/api";
import downloadFile from "@/utils/file";
import {
  API_FETCH_SOURCES,
  API_FETCH_SOURCES_FILE,
  API_FETCH_SOURCE,
  API_CREATE_SOURCE,
  API_DELETE_SOURCE,
  API_UPDATE_SOURCE,
} from "@/config/api.config";

import {
  SET_SOURCES_DATA,
  SET_SOURCE_DATA,
  REMOVE_SOURCE_DATA,
  MUTATE_FILTERS,
  MUTATE_EMPTY_FILTERS,
  MUTATE_DEFAULT_FILTERS,
  MUTATE_SELECTED_PAGE,
  MUTATE_LIMIT,
} from "./mutations";

export const FETCH_SOURCES = "FETCH_SOURCES";
export const FETCH_SOURCES_FILE = "FETCH_SOURCES_FILE";
export const FETCH_SOURCE = "FETCH_SOURCE";

export const CREATE_SOURCE = "CREATE_SOURCE";
export const DELETE_SOURCE = "DELETE_SOURCE";
export const UPDATE_SOURCE = "UPDATE_SOURCE";

export const SET_SELECTED_PAGE = "SET_SELECTED_PAGE";
export const SET_LIMIT = "SET_LIMIT";

export const SET_FILTERS = "SET_FILTERS";
export const SET_DEFAULT_FILTERS = "SET_DEFAULT_FILTERS";
export const SET_EMPTY_FILTERS = "SET_EMPTY_FILTERS";

export default {
  [FETCH_SOURCES]({ commit, getters }) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_FETCH_SOURCES,
        params: {
          limit: getters.limit,
          offset: getters.offset,
          sortBy: getters.formattedSort,
          search: getters.formattedFilters,
        },
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && !data.error) {
          commit(SET_SOURCES_DATA, data);
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  },
  [FETCH_SOURCES_FILE]({ getters }, option) {
    return new Promise((resolve, reject) => {
      return downloadFile({
        method: API_FETCH_SOURCES_FILE,
        params: {
          sortBy: getters.formattedSort,
          search: getters.formattedFilters,
          option: option,
        },
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && !data.error) {
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  },
  [FETCH_SOURCE]({ commit }, id) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_FETCH_SOURCE,
        parts: { id: id },
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && !data.error) {
          commit(SET_SOURCE_DATA, data);
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  },
  [CREATE_SOURCE](context, payload) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_CREATE_SOURCE,
        data: payload,
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && !data.error) {
          resolve(data);
        } else {
          reject(data.details);
        }
      });
    });
  },
  [UPDATE_SOURCE](context, payload) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_UPDATE_SOURCE,
        data: payload.editedItem,
        parts: { id: payload.id },
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && data) {
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  },
  [DELETE_SOURCE]({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_DELETE_SOURCE,
        parts: { id: id },
      })
        .then((response) => {
          let { status, data } = response;
          if (status === 200 && data) {
            commit(REMOVE_SOURCE_DATA, response.data);
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((err) => {
          reject(new Error(err));
        });
    });
  },
  [SET_SELECTED_PAGE]({ commit }, page) {
    commit(MUTATE_SELECTED_PAGE, page);
  },
  [SET_LIMIT]({ commit }, limit) {
    commit(MUTATE_LIMIT, limit);
  },
  [SET_FILTERS]({ commit }, data) {
    commit(MUTATE_FILTERS, data);
  },
  [SET_DEFAULT_FILTERS]({ commit }, data) {
    commit(MUTATE_DEFAULT_FILTERS, data);
  },
  [SET_EMPTY_FILTERS]({ commit }, data) {
    commit(MUTATE_EMPTY_FILTERS, data);
  },
};
