<template>
  <v-list-item-group v-model="selectedItem" color="primary">
    <router-link
      v-if="item.visible !== false"
      class="text-decoration-none"
      :to="item.route"
    >
      <v-list-item :value="item.route.name" link>
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          {{ item.title }}
        </v-list-item-title>
      </v-list-item>
    </router-link>
  </v-list-item-group>
</template>

<script>
export default {
  name: "NavItem",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data: function () {
    return {
      selectedItem: null,
    };
  },
  watch: {
    $route: {
      handler: function () {
        if (
          this.item.activeOn &&
          this.item.activeOn.indexOf(this.$route.name) !== -1
        ) {
          this.selectedItem = this.item.route.name;
        } else {
          this.selectedItem = this.$route.name;
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (
      this.item.activeOn &&
      this.item.activeOn.indexOf(this.$route.name) !== -1
    ) {
      this.selectedItem = this.item.route.name;
    } else {
      this.selectedItem = this.$route.name;
    }
  },
};
</script>
