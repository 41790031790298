export const SET_PACKAGES = "SET_PACKAGES";
export const MUTATE_SELECTED_PAGE = "MUTATE_SELECTED_PAGE";
export const MUTATE_LIMIT = "MUTATE_LIMIT";

export default {
  [SET_PACKAGES]: (state, sourcesData) => {
    state.packages = sourcesData.data;
    state.count = sourcesData.count;
  },
  [MUTATE_SELECTED_PAGE]: () => {
    // state.pagination.page = page;
  },
  [MUTATE_LIMIT]: (state, limit) => {
    state.limit = limit;
  },
};
