import apiCall from "@/utils/api";
import {
  API_FETCH_ADMINS,
  API_CREATE_ADMIN,
  API_DELETE_ADMIN,
  API_UPDATE_ADMIN,
  API_SET_ADMIN_PERMISSIONS,
  API_FETCH_ACL_RESOURCES,
} from "@/config/api.config";

import {
  SET_DATA,
  SET_RESOURCES,
  MUTATE_FILTERS,
  MUTATE_EMPTY_FILTERS,
  MUTATE_DEFAULT_FILTERS,
  MUTATE_SELECTED_PAGE,
  MUTATE_LIMIT,
} from "./mutations";

export const FETCH_ADMINS = "FETCH_ADMINS";
export const FETCH_RESOURCES = "FETCH_CUSTOMFETCH_RESOURCES_SOURCES_FILE";
export const DELETE_ADMIN = "DELETE_ADMIN";
export const UPDATE_ADMIN = "UPDATE_ADMIN";
export const SET_PERMISSIONS = "SET_PERMISSIONS";

export const SET_SELECTED_PAGE = "SET_SELECTED_PAGE";
export const SET_LIMIT = "SET_LIMIT";

export const SET_FILTERS = "SET_FILTERS";
export const SET_DEFAULT_FILTERS = "SET_DEFAULT_FILTERS";
export const SET_EMPTY_FILTERS = "SET_EMPTY_FILTERS";

export default {
  [FETCH_ADMINS]({ commit, getters }) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_FETCH_ADMINS,
        params: {
          limit: getters.limit,
          offset: getters.offset,
          sortBy: getters.formattedSort,
          search: getters.formattedFilters,
        },
      }).then((response) => {
        if (!response) {
          reject(null);
        }
        let { status, data } = response;
        if (status === 200 && !data.error) {
          commit(SET_DATA, data);
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  },
  [FETCH_RESOURCES]({ commit }) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_FETCH_ACL_RESOURCES,
      }).then((response) => {
        if (!response) {
          reject(null);
        }
        let { status, data } = response;
        if (status === 200 && !data.error) {
          commit(SET_RESOURCES, data);
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  },
  [UPDATE_ADMIN](context, { id, form }) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: id ? API_UPDATE_ADMIN : API_CREATE_ADMIN,
        data: form,
        parts: { id },
      }).then((response) => {
        if (!response) {
          reject(null);
        }
        let { status, data } = response;
        if (status === 200 && data) {
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  },
  [SET_PERMISSIONS](context, { id, form }) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_SET_ADMIN_PERMISSIONS,
        data: form,
        parts: { id },
      }).then((response) => {
        if (!response) {
          reject(null);
        }
        let { status, data } = response;
        if (status === 200 && data) {
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  },
  [DELETE_ADMIN](context, { id }) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_DELETE_ADMIN,
        parts: { id: id },
      }).then((response) => {
        if (!response) {
          reject(null);
        }
        let { status, data } = response;
        if (status === 200 && data) {
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  },
  [SET_SELECTED_PAGE]({ commit }, page) {
    commit(MUTATE_SELECTED_PAGE, page);
  },
  [SET_LIMIT]({ commit }, limit) {
    commit(MUTATE_LIMIT, limit);
  },
  [SET_FILTERS]({ commit }, data) {
    commit(MUTATE_FILTERS, data);
  },
  [SET_DEFAULT_FILTERS]({ commit }, data) {
    commit(MUTATE_DEFAULT_FILTERS, data);
  },
  [SET_EMPTY_FILTERS]({ commit }, data) {
    commit(MUTATE_EMPTY_FILTERS, data);
  },
};
