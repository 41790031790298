import Vue from "vue";
import _ from "lodash";
import store from "@/store";
import router from "@/router";
import { LOGOUT } from "@/store/modules/auth/mutations";
import { SET_USER_DATA } from "@/store/modules/user/mutations";
import { apiRoutes } from "@/config/api.config";
import { createCancelTokenHandler } from "./cancelTokenHandler";

const cancelTokenHandlerObject = createCancelTokenHandler(apiRoutes.methods);

const apiCall = ({ method, data, params, parts, headers = {} }) =>
  new Promise((resolve, reject) => {
    try {
      let action = _.get(apiRoutes.methods, [method]);
      let url = _.template(apiRoutes.host + action.url);
      let accessToken = store.getters["auth/accessToken"];
      if (accessToken) {
        headers["Authorization"] = `Bearer ${accessToken}`;
      }
      Vue.axios({
        url: url(parts),
        method: action.httpMethod,
        cancelToken:
          cancelTokenHandlerObject[method].handleRequestCancellation().token,
        data,
        params,
        headers,
      }).then(
        (res) => {
          resolve(res);
        },
        (error) => {
          if (error.response && error.response.status === 401) {
            store.commit(`auth/${LOGOUT}`);
            store.commit(`user/${SET_USER_DATA}`, null);
            if (router.currentRoute.name !== "LoginPage") {
              router.go({ name: "LoginPage" });
            } else {
              resolve(error.response);
            }
          } else {
            resolve(error.response);
          }
        }
      );
    } catch (err) {
      reject(new Error(err));
    }
  });

export default apiCall;
