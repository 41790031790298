import apiCall from "@/utils/api";
import {
  API_FETCH_PARSING_GROUPS,
  API_DELETE_PARSING_GROUP,
  API_CREATE_PARSING_GROUP,
  API_UPDATE_PARSING_GROUP,
  API_FETCH_PARSING_GROUP,
  API_FETCH_PARSING_GROUP_DICTIONARY,
} from "@/config/api.config";

import {
  SET_GROUPS,
  MUTATE_SELECTED_PAGE,
  MUTATE_LIMIT,
  SET_DICTIONARY,
} from "./mutations";

export const FETCH_PARSING_GROUPS = "FETCH_PARSING_GROUPS";
export const FETCH_PARSING_GROUP = "FETCH_PARSING_GROUP";
export const FETCH_PARSING_GROUPS_DICTIONARY = "FETCH_PARSING_GROUP_DICTIONARY";
export const DELETE_PARSING_GROUP = "DELETE_PARSING_GROUP";
export const SAVE_PARSING_GROUP = "SAVE_PARSING_GROUP";

export const SET_SELECTED_PAGE = "SET_SELECTED_PAGE";
export const SET_LIMIT = "SET_LIMIT";

export default {
  [FETCH_PARSING_GROUPS]({ commit, getters }) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_FETCH_PARSING_GROUPS,
        params: {
          limit: getters.limit,
          offset: getters.offset,
        },
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && !data.error) {
          commit(SET_GROUPS, data);
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  },
  [DELETE_PARSING_GROUP]: (context, { id }) => {
    return new Promise((resolve, reject) => {
      //Return async api handler
      apiCall({
        method: API_DELETE_PARSING_GROUP,
        parts: { id },
      })
        .then((response) => {
          let payload = response.data;
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [SAVE_PARSING_GROUP]: (context, { data, id }) => {
    return new Promise((resolve, reject) => {
      //Return async api handler
      apiCall({
        method: id ? API_UPDATE_PARSING_GROUP : API_CREATE_PARSING_GROUP,
        data,
        parts: { id },
      })
        .then((response) => {
          let payload = response.data;
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_PARSING_GROUP]: (context, { id }) => {
    return new Promise((resolve, reject) => {
      //Return async api handler
      apiCall({
        method: API_FETCH_PARSING_GROUP,
        parts: { id },
      })
        .then((response) => {
          //Provide the control to the next callback
          resolve(response.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [FETCH_PARSING_GROUPS_DICTIONARY]({ commit }) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_FETCH_PARSING_GROUP_DICTIONARY,
      }).then((response) => {
        try {
          let { status, data } = response;
          if (status === 200 && !data.error) {
            commit(SET_DICTIONARY, data);
            resolve(data);
          } else {
            reject(data);
          }
        } catch (e) {
          // eslint-disable-next-line no-empty
        }
      });
    });
  },
  [SET_SELECTED_PAGE]({ commit }, page) {
    commit(MUTATE_SELECTED_PAGE, page);
  },
  [SET_LIMIT]({ commit }, limit) {
    commit(MUTATE_LIMIT, limit);
  },
};
