import _ from "lodash";

export const RESOURCE_ACCOUNTS = "accounts";
export const RESOURCE_ACCOUNTS_MANAGEMENT = "accounts_management";
export const RESOURCE_ADMINS = "admins";
export const RESOURCE_ADMINS_MANAGEMENT = "admins_management";
export const RESOURCE_BALANCE_OPERATIONS = "balance_operations";
export const RESOURCE_BALANCE_OPERATIONS_MANAGEMENT =
  "balance_operations_management";
export const RESOURCE_CHANNELS = "channels";
export const RESOURCE_CHANNELS_MANAGEMENT = "channels_management";
export const RESOURCE_CUSTOM_SOURCES = "custom_sources";
export const RESOURCE_CUSTOM_SOURCES_MANAGEMENT = "custom_sources_management";
export const RESOURCE_PROXIES = "proxies";
export const RESOURCE_PROXIES_MANAGEMENT = "proxies_management";
export const RESOURCE_PROXY_PACKAGES = "proxy_packages";
export const RESOURCE_PROXY_PACKAGES_MANAGEMENT = "proxy_packages_management";
export const RESOURCE_SOURCES = "sources";
export const RESOURCE_SOURCES_MANAGEMENT = "sources_management";
export const RESOURCE_SUBSCRIPTION_PACKAGES = "subscription_packages";
export const RESOURCE_SUBSCRIPTION_PACKAGES_MANAGEMENT =
  "subscription_packages_management";
export const RESOURCE_TAGS = "tags";
export const RESOURCE_TAGS_MANAGEMENT = "tags_management";
export const RESOURCE_PARSING_GROUPS = "parsing_groups";
export const RESOURCE_PARSING_GROUPS_MANAGEMENT = "parsing_groups_management";
export const RESOURCE_PROJECTS = "projects";
export const RESOURCE_PROJECTS_MANAGEMENT = "projects_management";

export default {
  items: (state) => {
    return state.items;
  },
  aclResources: (state) => {
    return state.aclResources;
  },
  totalCount: (state) => {
    return state.count;
  },
  currentPage: (state) => {
    return state.pagination.page;
  },
  pageCount: (state) => {
    return Math.ceil(state.count / state.pagination.rowPerPage);
  },
  limit: (state) => {
    return state.pagination.rowPerPage;
  },
  offset: (state) => {
    return (state.pagination.page - 1) * state.pagination.rowPerPage;
  },
  filters: (state) => {
    return state.filters;
  },
  formattedFilters: (state) => {
    let filters = {};
    _.forIn(state.filters, function (value, key) {
      if (value !== null && (!(value instanceof Array) || value.length)) {
        if (value.length - 1 && value instanceof Array) {
          filters[key] = value.join(",");
        } else {
          filters[key] = value;
        }
      }
    });
    return filters;
  },
  formattedSort: (state) => {
    let formatted = state.sort.sortBy;
    if (state.sort.desc) {
      return `-${formatted}`;
    }
    return formatted;
  },
};
