import {
  MUTATE_ADD_SOURCE_FILTER,
  MUTATE_REMOVE_SOURCE_FILTER,
  MUTATE_SET_SOURCE_FILTERS,
  MUTATE_SET_DEFAULT_SOURCE_FILTER,
  MUTATE_ADD_CUSTOM_SOURCE_FILTER,
  MUTATE_REMOVE_CUSTOM_SOURCE_FILTER,
  MUTATE_SET_CUSTOM_SOURCE_FILTERS,
  MUTATE_SET_DEFAULT_CUSTOM_SOURCE_FILTER,
  MUTATE_ADD_CHANNEL_FILTER,
  MUTATE_REMOVE_CHANNEL_FILTER,
  MUTATE_SET_CHANNEL_FILTERS,
  MUTATE_SET_DEFAULT_CHANNEL_FILTER,
  MUTATE_ADD_SOURCE_LOG_FILTER,
  MUTATE_REMOVE_SOURCE_LOG_FILTER,
  MUTATE_SET_SOURCE_LOG_FILTERS,
  MUTATE_SET_DEFAULT_SOURCE_LOG_FILTER,
  MUTATE_ADD_TAG_FILTER,
  MUTATE_REMOVE_TAG_FILTER,
  MUTATE_SET_TAG_FILTERS,
  MUTATE_SET_DEFAULT_TAG_FILTER,
  MUTATE_ADD_PROXY_PACKAGE_FILTER,
  MUTATE_SET_DEFAULT_PROXY_PACKAGE_FILTER,
  MUTATE_SET_PROXY_PACKAGE_FILTERS,
  MUTATE_REMOVE_PROXY_PACKAGE_FILTER,
  MUTATE_ADD_PROXY_FILTER,
  MUTATE_SET_DEFAULT_PROXY_FILTER,
  MUTATE_SET_PROXY_FILTERS,
  MUTATE_REMOVE_PROXY_FILTER,
  MUTATE_ADD_ACCOUNT_FILTER,
  MUTATE_SET_DEFAULT_ACCOUNT_FILTER,
  MUTATE_SET_ACCOUNT_FILTERS,
  MUTATE_REMOVE_ACCOUNT_FILTER,
  MUTATE_ADD_USER_LOGS_FILTER,
  MUTATE_SET_USER_LOGS_FILTERS,
  MUTATE_SET_DEFAULT_USER_LOGS_FILTER,
  MUTATE_REMOVE_USER_LOGS_FILTER,
  MUTATE_ADD_ADMIN_FILTER,
  MUTATE_SET_ADMIN_FILTERS,
  MUTATE_SET_DEFAULT_ADMIN_FILTER,
  MUTATE_REMOVE_ADMIN_FILTER,
  MUTATE_ADD_PROJECT_FILTER,
  MUTATE_SET_PROJECT_FILTERS,
  MUTATE_SET_DEFAULT_PROJECT_FILTER,
  MUTATE_REMOVE_PROJECT_FILTER,
  MUTATE_ADD_PROJECT_LOG_FILTER,
  MUTATE_REMOVE_PROJECT_LOG_FILTER,
  MUTATE_SET_PROJECT_LOG_FILTERS,
  MUTATE_SET_DEFAULT_PROJECT_LOG_FILTER,
  MUTATE_ADD_PROJECT_MANUAL_ARTICLES_FILTER,
  MUTATE_REMOVE_PROJECT_MANUAL_ARTICLES_FILTER,
  MUTATE_SET_PROJECT_MANUAL_ARTICLES_FILTERS,
  MUTATE_SET_DEFAULT_PROJECT_MANUAL_ARTICLES_FILTER,
  MUTATE_ADD_PROJECT_TASKS_FILTER,
  MUTATE_REMOVE_PROJECT_TASKS_FILTER,
  MUTATE_SET_PROJECT_TASKS_FILTERS,
  MUTATE_SET_DEFAULT_PROJECT_TASKS_FILTER,
} from "./mutations";

// Source
export const ADD_SOURCE_FILTER = "ADD_SOURCE_FILTER";
export const REMOVE_SOURCE_FILTER = "REMOVE_SOURCE_FILTER";
export const SET_SOURCE_FILTERS = "SET_SOURCE_FILTERS";
export const SET_DEFAULT_SOURCE_FILTERS = "SET_DEFAULT_SOURCE_FILTERS";

// Custom source
export const ADD_CUSTOM_SOURCE_FILTER = "ADD_CUSTOM_SOURCE_FILTER";
export const REMOVE_CUSTOM_SOURCE_FILTER = "REMOVE_CUSTOM_SOURCE_FILTER";
export const SET_CUSTOM_SOURCE_FILTERS = "SET_CUSTOM_SOURCE_FILTERS";
export const SET_DEFAULT_CUSTOM_SOURCE_FILTERS =
  "SET_DEFAULT_CUSTOM_SOURCE_FILTERS";

// Channel
export const ADD_CHANNEL_FILTER = "ADD_CHANNEL_FILTER";
export const REMOVE_CHANNEL_FILTER = "REMOVE_CHANNEL_FILTER";
export const SET_CHANNEL_FILTERS = "SET_CHANNEL_FILTERS";
export const SET_DEFAULT_CHANNEL_FILTERS = "SET_DEFAULT_CHANNEL_FILTERS";

// Source log
export const ADD_SOURCE_LOG_FILTER = "ADD_SOURCE_LOG_FILTER";
export const REMOVE_SOURCE_LOG_FILTER = "REMOVE_SOURCE_LOG_FILTER";
export const SET_SOURCE_LOG_FILTERS = "SET_SOURCE_LOG_FILTERS";
export const SET_DEFAULT_SOURCE_LOG_FILTERS = "SET_DEFAULT_SOURCE_LOG_FILTERS";

// Tag
export const ADD_TAG_FILTER = "ADD_TAG_FILTER";
export const REMOVE_TAG_FILTER = "REMOVE_TAG_FILTER";
export const SET_TAG_FILTERS = "SET_TAG_FILTERS";
export const SET_DEFAULT_TAG_FILTERS = "SET_DEFAULT_TAG_FILTERS";

// Proxy package
export const ADD_PROXY_PACKAGE_FILTER = "ADD_PROXY_PACKAGE_FILTER";
export const REMOVE_PROXY_PACKAGE_FILTER = "REMOVE_PROXY_PACKAGE_FILTER";
export const SET_PROXY_PACKAGE_FILTERS = "SET_PROXY_PACKAGE_FILTERS";
export const SET_DEFAULT_PROXY_PACKAGE_FILTERS =
  "SET_DEFAULT_PROXY_PACKAGE_FILTERS";

// Proxy
export const ADD_PROXY_FILTER = "ADD_PROXY_FILTER";
export const REMOVE_PROXY_FILTER = "REMOVE_PROXY_FILTER";
export const SET_PROXY_FILTERS = "SET_PROXY_FILTERS";
export const SET_DEFAULT_PROXY_FILTERS = "SET_DEFAULT_PROXY_FILTERS";

// Accounts
export const ADD_ACCOUNT_FILTER = "ADD_ACCOUNT_FILTER";
export const REMOVE_ACCOUNT_FILTER = "REMOVE_ACCOUNT_FILTER";
export const SET_ACCOUNT_FILTERS = "SET_ACCOUNT_FILTERS";
export const SET_DEFAULT_ACCOUNT_FILTERS = "SET_DEFAULT_ACCOUNT_FILTERS";

// User logs
export const ADD_USER_LOGS_FILTER = "ADD_USER_LOGS_FILTER";
export const REMOVE_USER_LOGS_FILTER = "REMOVE_USER_LOGS_FILTER";
export const SET_USER_LOGS_FILTERS = "SET_USER_LOGS_FILTERS";
export const SET_DEFAULT_USER_LOGS_FILTERS = "SET_DEFAULT_USER_LOGS_FILTERS";

// Admin
export const ADD_ADMIN_FILTER = "ADD_ADMIN_FILTER";
export const REMOVE_ADMIN_FILTER = "REMOVE_ADMIN_FILTER";
export const SET_ADMIN_FILTERS = "SET_ADMIN_FILTERS";
export const SET_DEFAULT_ADMIN_FILTERS = "SET_DEFAULT_ADMIN_FILTERS";

// Project
export const ADD_PROJECT_FILTER = "ADD_PROJECT_FILTER";
export const REMOVE_PROJECT_FILTER = "REMOVE_PROJECT_FILTER";
export const SET_PROJECT_FILTERS = "SET_PROJECT_FILTERS";
export const SET_DEFAULT_PROJECT_FILTERS = "SET_DEFAULT_PROJECT_FILTERS";

// Project logs
export const ADD_PROJECT_LOG_FILTER = "ADD_PROJECT_LOG_FILTER";
export const REMOVE_PROJECT_LOG_FILTER = "REMOVE_PROJECT_LOG_FILTER";
export const SET_PROJECT_LOG_FILTERS = "SET_PROJECT_LOG_FILTERS";
export const SET_DEFAULT_PROJECT_LOG_FILTERS =
  "SET_DEFAULT_PROJECT_LOG_FILTERS";

// Project manual articles
export const ADD_PROJECT_MANUAL_ARTICLES_FILTER =
  "ADD_PROJECT_MANUAL_ARTICLES_FILTER";
export const REMOVE_PROJECT_MANUAL_ARTICLES_FILTER =
  "REMOVE_PROJECT_MANUAL_ARTICLES_FILTER";
export const SET_PROJECT_MANUAL_ARTICLES_FILTERS =
  "SET_PROJECT_MANUAL_ARTICLES_FILTERS";
export const SET_DEFAULT_PROJECT_MANUAL_ARTICLES_FILTERS =
  "SET_DEFAULT_PROJECT_MANUAL_ARTICLES_FILTERS";

// Project tasks
export const ADD_PROJECT_TASKS_FILTER = "ADD_PROJECT_TASKS_FILTER";
export const REMOVE_PROJECT_TASKS_FILTER = "REMOVE_PROJECT_TASKS_FILTER";
export const SET_PROJECT_TASKS_FILTERS = "SET_PROJECT_TASKS_FILTERS";
export const SET_DEFAULT_PROJECT_TASKS_FILTERS =
  "SET_DEFAULT_PROJECT_TASKS_FILTERS";

export default {
  // Source
  [ADD_SOURCE_FILTER]({ commit }, data) {
    commit(MUTATE_ADD_SOURCE_FILTER, data);
  },
  [REMOVE_SOURCE_FILTER]({ commit }, data) {
    commit(MUTATE_REMOVE_SOURCE_FILTER, data);
  },
  [SET_SOURCE_FILTERS]({ commit }, data) {
    commit(MUTATE_SET_SOURCE_FILTERS, data);
  },
  [SET_DEFAULT_SOURCE_FILTERS]({ commit }, data) {
    commit(MUTATE_SET_DEFAULT_SOURCE_FILTER, data);
  },
  // Custom Source
  [ADD_CUSTOM_SOURCE_FILTER]({ commit }, data) {
    commit(MUTATE_ADD_CUSTOM_SOURCE_FILTER, data);
  },
  [REMOVE_CUSTOM_SOURCE_FILTER]({ commit }, data) {
    commit(MUTATE_REMOVE_CUSTOM_SOURCE_FILTER, data);
  },
  [SET_CUSTOM_SOURCE_FILTERS]({ commit }, data) {
    commit(MUTATE_SET_CUSTOM_SOURCE_FILTERS, data);
  },
  [SET_DEFAULT_CUSTOM_SOURCE_FILTERS]({ commit }, data) {
    commit(MUTATE_SET_DEFAULT_CUSTOM_SOURCE_FILTER, data);
  },
  // Channel
  [ADD_CHANNEL_FILTER]({ commit }, data) {
    commit(MUTATE_ADD_CHANNEL_FILTER, data);
  },
  [REMOVE_CHANNEL_FILTER]({ commit }, data) {
    commit(MUTATE_REMOVE_CHANNEL_FILTER, data);
  },
  [SET_CHANNEL_FILTERS]({ commit }, data) {
    commit(MUTATE_SET_CHANNEL_FILTERS, data);
  },
  [SET_DEFAULT_CHANNEL_FILTERS]({ commit }, data) {
    commit(MUTATE_SET_DEFAULT_CHANNEL_FILTER, data);
  },
  // Source log
  [ADD_SOURCE_LOG_FILTER]({ commit }, data) {
    commit(MUTATE_ADD_SOURCE_LOG_FILTER, data);
  },
  [REMOVE_SOURCE_LOG_FILTER]({ commit }, data) {
    commit(MUTATE_REMOVE_SOURCE_LOG_FILTER, data);
  },
  [SET_SOURCE_LOG_FILTERS]({ commit }, data) {
    commit(MUTATE_SET_SOURCE_LOG_FILTERS, data);
  },
  [SET_DEFAULT_SOURCE_LOG_FILTERS]({ commit }, data) {
    commit(MUTATE_SET_DEFAULT_SOURCE_LOG_FILTER, data);
  },
  // Tag
  [ADD_TAG_FILTER]({ commit }, data) {
    commit(MUTATE_ADD_TAG_FILTER, data);
  },
  [REMOVE_TAG_FILTER]({ commit }, data) {
    commit(MUTATE_REMOVE_TAG_FILTER, data);
  },
  [SET_TAG_FILTERS]({ commit }, data) {
    commit(MUTATE_SET_TAG_FILTERS, data);
  },
  [SET_DEFAULT_TAG_FILTERS]({ commit }, data) {
    commit(MUTATE_SET_DEFAULT_TAG_FILTER, data);
  },
  // Proxy package
  [ADD_PROXY_PACKAGE_FILTER]({ commit }, data) {
    commit(MUTATE_ADD_PROXY_PACKAGE_FILTER, data);
  },
  [REMOVE_PROXY_PACKAGE_FILTER]({ commit }, data) {
    commit(MUTATE_REMOVE_PROXY_PACKAGE_FILTER, data);
  },
  [SET_PROXY_PACKAGE_FILTERS]({ commit }, data) {
    commit(MUTATE_SET_PROXY_PACKAGE_FILTERS, data);
  },
  [SET_DEFAULT_PROXY_PACKAGE_FILTERS]({ commit }, data) {
    commit(MUTATE_SET_DEFAULT_PROXY_PACKAGE_FILTER, data);
  },
  // Proxy
  [ADD_PROXY_FILTER]({ commit }, data) {
    commit(MUTATE_ADD_PROXY_FILTER, data);
  },
  [REMOVE_PROXY_FILTER]({ commit }, data) {
    commit(MUTATE_REMOVE_PROXY_FILTER, data);
  },
  [SET_PROXY_FILTERS]({ commit }, data) {
    commit(MUTATE_SET_PROXY_FILTERS, data);
  },
  [SET_DEFAULT_PROXY_FILTERS]({ commit }, data) {
    commit(MUTATE_SET_DEFAULT_PROXY_FILTER, data);
  },
  // Accounts
  [ADD_ACCOUNT_FILTER]({ commit }, data) {
    commit(MUTATE_ADD_ACCOUNT_FILTER, data);
  },
  [REMOVE_ACCOUNT_FILTER]({ commit }, data) {
    commit(MUTATE_REMOVE_ACCOUNT_FILTER, data);
  },
  [SET_ACCOUNT_FILTERS]({ commit }, data) {
    commit(MUTATE_SET_ACCOUNT_FILTERS, data);
  },
  [SET_DEFAULT_ACCOUNT_FILTERS]({ commit }, data) {
    commit(MUTATE_SET_DEFAULT_ACCOUNT_FILTER, data);
  },
  // User logs
  [ADD_USER_LOGS_FILTER]({ commit }, data) {
    commit(MUTATE_ADD_USER_LOGS_FILTER, data);
  },
  [REMOVE_USER_LOGS_FILTER]({ commit }, data) {
    commit(MUTATE_REMOVE_USER_LOGS_FILTER, data);
  },
  [SET_USER_LOGS_FILTERS]({ commit }, data) {
    commit(MUTATE_SET_USER_LOGS_FILTERS, data);
  },
  [SET_DEFAULT_USER_LOGS_FILTERS]({ commit }, data) {
    commit(MUTATE_SET_DEFAULT_USER_LOGS_FILTER, data);
  },
  // Admin
  [ADD_ADMIN_FILTER]({ commit }, data) {
    commit(MUTATE_ADD_ADMIN_FILTER, data);
  },
  [REMOVE_ADMIN_FILTER]({ commit }, data) {
    commit(MUTATE_REMOVE_ADMIN_FILTER, data);
  },
  [SET_ADMIN_FILTERS]({ commit }, data) {
    commit(MUTATE_SET_ADMIN_FILTERS, data);
  },
  [SET_DEFAULT_ADMIN_FILTERS]({ commit }, data) {
    commit(MUTATE_SET_DEFAULT_ADMIN_FILTER, data);
  },
  // Project
  [ADD_PROJECT_FILTER]({ commit }, data) {
    commit(MUTATE_ADD_PROJECT_FILTER, data);
  },
  [REMOVE_PROJECT_FILTER]({ commit }, data) {
    commit(MUTATE_REMOVE_PROJECT_FILTER, data);
  },
  [SET_PROJECT_FILTERS]({ commit }, data) {
    commit(MUTATE_SET_PROJECT_FILTERS, data);
  },
  [SET_DEFAULT_PROJECT_FILTERS]({ commit }, data) {
    commit(MUTATE_SET_DEFAULT_PROJECT_FILTER, data);
  },
  // Project logs
  [ADD_PROJECT_LOG_FILTER]({ commit }, data) {
    commit(MUTATE_ADD_PROJECT_LOG_FILTER, data);
  },
  [REMOVE_PROJECT_LOG_FILTER]({ commit }, data) {
    commit(MUTATE_REMOVE_PROJECT_LOG_FILTER, data);
  },
  [SET_PROJECT_LOG_FILTERS]({ commit }, data) {
    commit(MUTATE_SET_PROJECT_LOG_FILTERS, data);
  },
  [SET_DEFAULT_PROJECT_LOG_FILTERS]({ commit }, data) {
    commit(MUTATE_SET_DEFAULT_PROJECT_LOG_FILTER, data);
  },
  // Project manual articles
  [ADD_PROJECT_MANUAL_ARTICLES_FILTER]({ commit }, data) {
    commit(MUTATE_ADD_PROJECT_MANUAL_ARTICLES_FILTER, data);
  },
  [REMOVE_PROJECT_MANUAL_ARTICLES_FILTER]({ commit }, data) {
    commit(MUTATE_REMOVE_PROJECT_MANUAL_ARTICLES_FILTER, data);
  },
  [SET_PROJECT_MANUAL_ARTICLES_FILTERS]({ commit }, data) {
    commit(MUTATE_SET_PROJECT_MANUAL_ARTICLES_FILTERS, data);
  },
  [SET_DEFAULT_PROJECT_MANUAL_ARTICLES_FILTERS]({ commit }, data) {
    commit(MUTATE_SET_DEFAULT_PROJECT_MANUAL_ARTICLES_FILTER, data);
  },
  // Project tasks
  [ADD_PROJECT_TASKS_FILTER]({ commit }, data) {
    commit(MUTATE_ADD_PROJECT_TASKS_FILTER, data);
  },
  [REMOVE_PROJECT_TASKS_FILTER]({ commit }, data) {
    commit(MUTATE_REMOVE_PROJECT_TASKS_FILTER, data);
  },
  [SET_PROJECT_TASKS_FILTERS]({ commit }, data) {
    commit(MUTATE_SET_PROJECT_TASKS_FILTERS, data);
  },
  [SET_DEFAULT_PROJECT_TASKS_FILTERS]({ commit }, data) {
    commit(MUTATE_SET_DEFAULT_PROJECT_TASKS_FILTER, data);
  },
};
