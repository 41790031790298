import _ from "lodash";
import { defaultFilters, emptyFilters } from "./state";

export const SET_SOURCES_DATA = "SET_SOURCES_DATA";
export const ADD_SOURCE_DATA = "ADD_SOURCE_DATA";
export const SET_SOURCE_DATA = "SET_SOURCE_DATA";
export const REMOVE_SOURCE_DATA = "REMOVE_SOURCE_DATA";
export const MUTATE_SELECTED_PAGE = "MUTATE_SELECTED_PAGE";
export const MUTATE_LIMIT = "MUTATE_LIMIT";

export const MUTATE_FILTERS = "SET_FILTERS";
export const MUTATE_DEFAULT_FILTERS = "SET_DEFAULT_FILTERS";
export const MUTATE_EMPTY_FILTERS = "SET_EMPTY_FILTERS";

export default {
  [SET_SOURCES_DATA]: (state, sourcesData) => {
    state.sources = sourcesData.data;
    state.count = sourcesData.count;
  },
  [SET_SOURCE_DATA]: (state, sourceData) => {
    state.sourceItem = sourceData.data;
    const item = state.sources.filter(
      (source) => source.id === sourceData.data.id
    );
    const index = state.sources.indexOf(item[0]);
    if (~index) {
      state.sources[index] = sourceData.data;
    }
  },
  [ADD_SOURCE_DATA]: (state, sourceData) => {
    if (state.sources && typeof state.sources === "object") {
      state.sources.push(sourceData);
    }
  },
  [REMOVE_SOURCE_DATA]: (state, sourceData) => {
    if (state.sources && typeof state.sources === "object") {
      console.log("Delete source: " + sourceData);
    }
  },
  [MUTATE_SELECTED_PAGE]: () => {
    // state.pagination.page = page;
  },
  [MUTATE_LIMIT]: (state, limit) => {
    state.limit = limit;
  },
  [MUTATE_FILTERS]: (state, filters) => {
    if (!_.isEqual(_.merge({}, state.filters), filters)) {
      state.filters = filters;
    }
  },
  [MUTATE_DEFAULT_FILTERS]: (state) => {
    state.filters = _.merge({}, defaultFilters);
  },
  [MUTATE_EMPTY_FILTERS]: (state) => {
    state.filters = _.merge({}, emptyFilters);
  },
};
