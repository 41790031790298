import {
  LOGIN_START,
  LOGIN_STOP,
  UPDATE_ACCESS_TOKEN,
  UPDATE_REFRESH_TOKEN,
  LOGOUT,
} from "./mutations";

export const AUTH_LOGIN = "AUTH_LOGIN";
export const AUTH_LOGOUT = "AUTH_LOGOUT";

import apiCall from "@/utils/api";
import { EventBus } from "@/utils";
import { API_AUTH_LOGIN } from "@/config/api.config";
import { API_AUTH_LOGOUT } from "@/config/api.config";
import { FETCH_PROFILE } from "@/store/modules/user/actions";
import { FAILED_AUTHENTICATION } from "@/utils/events";

import { SET_USER_DATA } from "@/store/modules/user/mutations";

export default {
  [AUTH_LOGIN]({ commit, dispatch }, userData) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_AUTH_LOGIN,
        data: userData,
      })
        .then((response) => {
          // update state
          let { status, data } = response;
          if (status === 200 && data) {
            commit(LOGIN_STOP, null);
            commit(LOGIN_START);
            commit(UPDATE_ACCESS_TOKEN, data.accessToken);
            commit(UPDATE_REFRESH_TOKEN, data.refreshToken);
            dispatch(`user/${FETCH_PROFILE}`, null, { root: true });
            resolve(data);
          } else {
            reject(data);
          }
        })
        .catch((error) => {
          console.log(error);
          EventBus.$emit(FAILED_AUTHENTICATION, "Invalid credentials");
        });
    });
  },
  [AUTH_LOGOUT]({ commit }) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_AUTH_LOGOUT,
      })
        .then((response) => {
          // update state
          let { status, data } = response;
          if (status === 200 && data) {
            // nullify state
            commit(LOGOUT);
            commit(`user/${SET_USER_DATA}`, null, { root: true });
            resolve();
          } else {
            reject();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
};
