export default function acl(resource) {
  return function ({ next, store, path }) {
    if (
      !store.getters["user/currentUser"] ||
      (store.getters["user/currentUser"]["role"] !== "superadmin" &&
        store.getters["user/currentUser"]["permissions"][resource] === false)
    ) {
      if (!store.getters["auth/isAuthenticated"]) {
        return next({
          name: "LoginPage",
        });
      } else {
        return next({
          name: "NotAllowed",
          query: { url: encodeURIComponent(path) },
        });
      }
    }
    return next();
  };
}
