import { isValidJwt } from "@/utils";

export default {
  isAuthenticated: (state) => {
    return isValidJwt(state.accessToken);
  },
  accessToken: (state) => {
    return state.accessToken;
  },
};
