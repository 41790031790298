<template>
  <v-navigation-drawer
    app
    width="220"
    permanent
    class="elevation-3"
    :expand-on-hover="$vuetify.breakpoint.smAndDown"
  >
    <v-list>
      <v-list-item class="px-14 mx-0" dense>
        <v-list-item>
          <v-avatar size="80">
            <img :src="require('@/assets/pulse_logo.svg')" alt="" />
          </v-avatar>
        </v-list-item>
      </v-list-item>

      <v-list-item link>
        <v-list-item-content>
          <v-list-item-title class="title px-5 outline--text" dense
            ><p v-if="this.$store.getters['user/currentUser']">
              {{ this.$store.getters["user/currentUser"].name }}
            </p></v-list-item-title
          >
          <v-list-item-subtitle class="px-4 outline--text" dense
            ><p v-if="this.$store.getters['user/currentUser']">
              {{ this.$store.getters["user/currentUser"].email }}
            </p></v-list-item-subtitle
          >
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-divider></v-divider>
    <v-list shaped dense>
      <v-list-item-group v-model="selectedItem" color="primary">
        <component
          v-for="(linkItem, i) in menuItems"
          :is="linkItem.nested ? 'nav-group' : 'nav-item'"
          :key="i"
          :selected="linkItem.group === groupMap[selectedItem]"
          :item="linkItem"
          color="primary"
        ></component>
      </v-list-item-group>
    </v-list>
    <v-divider></v-divider>
    <v-list nav dense>
      <v-list-item link @click="logout">
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-title>
          {{ "Logout" }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import _ from "lodash";
import NavItem from "@/views/layout/parts/NavItem";
import NavGroup from "@/views/layout/parts/NavGroup";

import {
  RESOURCE_ACCOUNTS,
  RESOURCE_ADMINS,
  RESOURCE_CUSTOM_SOURCES,
  RESOURCE_PARSING_GROUPS, RESOURCE_PROJECTS,
  RESOURCE_PROXY_PACKAGES,
  RESOURCE_SOURCES,
  RESOURCE_SUBSCRIPTION_PACKAGES,
  RESOURCE_TAGS,
} from "@/store/modules/admin/getters";

import acl from "@/mixin/acl";

export default {
  name: "Nav",
  mixins: [acl],
  components: { NavGroup, NavItem },
  data: function () {
    return {
      selectedItem: null,
    };
  },
  computed: {
    menuItems: function () {
      return [
        {
          route: { name: "HomePage" },
          title: "Dashboard",
          icon: "mdi-home",
          visible: true,
        },
        {
          route: { name: "SourceListPage" },
          title: "System Sources",
          icon: "mdi-rocket",
          activeOn: ["SourceListPage", "SourceItemPage"],
          visible: this.hasAccess([RESOURCE_SOURCES]),
        },
        {
          route: { name: "CustomSourceListPage" },
          title: "Custom Sources",
          icon: "mdi-rocket-launch",
          activeOn: ["CustomSourceListPage", "CustomSourceItemPage"],
          visible: this.hasAccess([RESOURCE_CUSTOM_SOURCES]),
        },
        {
          route: { name: "ParsingGroups" },
          title: "Parsing groups",
          icon: "mdi-group",
          activeOn: ["ParsingGroups"],
          visible: this.hasAccess([RESOURCE_PARSING_GROUPS]),
        },
        {
          route: { name: "TagListPage" },
          title: "System Tags",
          icon: "mdi-tag",
          activeOn: ["TagListPage"],
          visible: this.hasAccess([RESOURCE_TAGS]),
        },
        {
          route: { name: "ProxyPackageListPage" },
          title: "System Proxies",
          icon: "mdi-arrow-decision",
          activeOn: ["ProxyPackageListPage", "ProxyListPage"],
          visible: this.hasAccess([RESOURCE_PROXY_PACKAGES]),
        },
        {
          route: { name: "AccountListPage" },
          title: "Accounts",
          icon: "mdi-account-multiple",
          activeOn: ["AccountListListPage", "AccountLogsPage", "AccountTransactionsPage", "AccountPackagesPage"],
          visible: this.hasAccess([RESOURCE_ACCOUNTS]),
        },
        {
          route: { name: "ProjectsListPage" },
          title: "Projects",
          icon: "mdi-briefcase",
          activeOn: ["ProjectsListPage", 'ProjectItemLogs', 'ProjectManualArticlesPage'],
          visible: this.hasAccess([RESOURCE_PROJECTS]),
        },
        {
          route: { name: "SubscriptionListPage" },
          title: "Package Settings",
          icon: "mdi-package-variant-plus",
          activeOn: ["SubscriptionListPage"],
          visible: this.hasAccess([RESOURCE_SUBSCRIPTION_PACKAGES]),
        },
        {
          route: { name: "AdminManagement" },
          title: "Administrators",
          icon: "mdi-shield-crown-outline",
          activeOn: ["AdminManagement"],
          visible: this.hasAccess([RESOURCE_ADMINS]),
        },
      ];
    },
    plainMenuMap: function () {
      const getNestedRecursive = function (nestedItems) {
        return nestedItems;
      };

      let items = {};
      let index = 0;
      this.menuItems.forEach(function (item) {
        if (item.nested !== undefined) {
          const nestedItems = getNestedRecursive(item.nested);
          nestedItems.forEach(function (nestedItem) {
            items[nestedItem.route.name] = index;
          });
          index++;
        } else {
          if (item.activeOn) {
            item.activeOn.forEach(function (routeName) {
              items[routeName] = item.route.name;
            });
          } else {
            items[item.route.name] = item.route.name;
          }
        }
      });

      return items;
    },
    groupMap: function () {
      let items = [];
      let index = 0;
      this.menuItems.forEach(function (item) {
        if (item.nested !== undefined) {
          items[index++] = item.group;
        }
      });

      return items;
    },
  },
  watch: {
    $route: {
      handler: function () {
        const vm = this;
        const routeName = this.$route.name;
        this.selectedItem = this.plainMenuMap[routeName];
        _.forIn(this.plainMenuMap, function (value, key) {
          if (routeName === key) {
            vm.selectedItem = value;
          }
        });
      },
      deep: true,
    },
  },
  methods: {
    logout: function () {
      this.$store
        .dispatch("auth/AUTH_LOGOUT")
        .then(() => {
          this.$router.push({ name: "LoginPage" });
        })
        .catch((err) => console.log(err));
    },
  },
  mounted() {
    this.selectedItem = this.plainMenuMap[this.$route.name];
  },
};
</script>
