import _ from "lodash";
import apiCall from "@/utils/api";
import downloadFile from "@/utils/file";

import {
  MUTATE_DEFAULT_FILTERS,
  MUTATE_EMPTY_FILTERS,
  MUTATE_FILTERS,
  MUTATE_SELECTED_PAGE,
  MUTATE_SOURCE_ID,
  PUSH_CHANNEL_DATA,
  SET_CHANNEL_DATA,
  SET_CHANNELS_DATA,
} from "./mutations";
import {
  API_FETCH_CHANNELS,
  API_FETCH_CHANNELS_FILE,
} from "@/config/api.config";
import {
  API_CREATE_CHANNEL,
  API_DELETE_CHANNEL,
  API_FETCH_CHANNEL,
  API_UPDATE_CHANNEL,
  API_FETCH_CHANNEL_PARSING_HISTORY,
} from "@/config/api.config";

export const FETCH_CHANNELS = "FETCH_CHANNELS";
export const FETCH_CHANNELS_FILE = "FETCH_CHANNELS_FILE";
export const FETCH_CHANNEL = "FETCH_CHANNEL";
export const CREATE_CHANNEL = "CREATE_CHANNEL";
export const DELETE_CHANNEL = "DELETE_CHANNEL";
export const UPDATE_CHANNEL = "UPDATE_CHANNEL";
export const FETCH_CHANNEL_PARSING_HISTORY = "FETCH_CHANNEL_PARSING_HISTORY";
export const SET_SOURCE_ID = "SET_SOURCE_ID";
export const SET_SELECTED_PAGE = "SET_SELECTED_PAGE";
export const SET_FILTERS = "SET_FILTERS";
export const SET_DEFAULT_FILTERS = "SET_DEFAULT_FILTERS";
export const SET_EMPTY_FILTERS = "SET_EMPTY_FILTERS";

export default {
  [FETCH_CHANNELS]({ commit, getters }, { sourceId }) {
    return apiCall({
      method: API_FETCH_CHANNELS,
      params: {
        limit: getters.limit,
        offset: getters.offset,
        sortBy: getters.formattedSort,
        search: _.merge({ sourceId }, getters.formattedFilters),
      },
    }).then((response) => {
      try {
        if (response.status === 200 && response.data) {
          commit(SET_CHANNELS_DATA, response.data);
        } else {
          console.log(response);
        }
      } catch (e) {
        // eslint-disable-next-line no-empty
      }
    });
  },
  [FETCH_CHANNELS_FILE]({ getters }, { sourceId }) {
    return new Promise((resolve, reject) => {
      return downloadFile({
        method: API_FETCH_CHANNELS_FILE,
        params: {
          sortBy: getters.formattedSort,
          search: _.merge({ sourceId }, getters.formattedFilters),
        },
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && !data.error) {
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  },
  [FETCH_CHANNEL]({ commit }, { itemId, how }) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_FETCH_CHANNEL,
        parts: { id: itemId },
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && !data.error) {
          if (how === "push") {
            commit(PUSH_CHANNEL_DATA, data);
          } else {
            commit(SET_CHANNEL_DATA, data);
          }
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  },
  [CREATE_CHANNEL](context, payload) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_CREATE_CHANNEL,
        data: payload,
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && !data.error) {
          resolve(data.data);
        } else {
          reject(data.details);
        }
      });
    });
  },
  [DELETE_CHANNEL](context, id) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_DELETE_CHANNEL,
        parts: { id: id },
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && !data.error) {
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  },
  [UPDATE_CHANNEL](context, payload) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_UPDATE_CHANNEL,
        data: payload.editedItem,
        parts: { id: payload.id },
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && !data.error) {
          resolve(data);
        } else {
          reject(data.details);
        }
      });
    });
  },
  [FETCH_CHANNEL_PARSING_HISTORY](context, { id }) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_FETCH_CHANNEL_PARSING_HISTORY,
        parts: { id },
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && !data.error) {
          resolve(data);
        } else {
          reject(data.details);
        }
      });
    });
  },
  [SET_SOURCE_ID]({ commit }, data) {
    commit(MUTATE_SOURCE_ID, data);
  },
  [SET_SELECTED_PAGE]({ commit }, page) {
    commit(MUTATE_SELECTED_PAGE, page);
  },
  [SET_FILTERS]({ commit }, data) {
    commit(MUTATE_FILTERS, data);
  },
  [SET_DEFAULT_FILTERS]({ commit }, data) {
    commit(MUTATE_DEFAULT_FILTERS, data);
  },
  [SET_EMPTY_FILTERS]({ commit }, data) {
    commit(MUTATE_EMPTY_FILTERS, data);
  },
};
