<template>
  <v-app>
    <nav-menu></nav-menu>
    <v-main>
      <v-container class="spacing-playground pa-7" fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import NavMenu from "./parts/Nav";
export default {
  name: "DefaultLayout",
  components: { NavMenu },
};
</script>
