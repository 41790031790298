import _ from "lodash";

import { defaultFilters, emptyFilters } from "./state";

export const SET_LOGS_DATA = "SET_LOGS_DATA";
export const MUTATE_SELECTED_PAGE = "MUTATE_SELECTED_PAGE";
export const MUTATE_FILTERS = "MUTATE_FILTERS";
export const MUTATE_DEFAULT_FILTERS = "MUTATE_DEFAULT_FILTERS";
export const MUTATE_EMPTY_FILTERS = "MUTATE_EMPTY_FILTERS";

export default {
  [SET_LOGS_DATA]: (state, data) => {
    state.logs = data.data;
    state.count = data.count;
  },
  [MUTATE_SELECTED_PAGE]: (state, page) => {
    state.pagination.page = page;
  },
  [MUTATE_FILTERS]: (state, filters) => {
    if (!_.isEqual(_.merge({}, state.filters), filters)) {
      state.filters = filters;
    }
  },
  [MUTATE_DEFAULT_FILTERS]: (state) => {
    state.filters = _.merge({}, defaultFilters);
  },
  [MUTATE_EMPTY_FILTERS]: (state) => {
    state.filters = _.merge({}, emptyFilters);
  },
};
