import Vue from "vue";
import Router from "vue-router";
import store from "@/store";
import routes from "./routes";

import middlewarePipeline from "./middlewarePipeline";

Vue.use(Router);

const router = new Router({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  if (!to.meta.middleware) {
    return next();
  }
  const route = router.resolve(to);
  const path = new URL(route.href, window.location.origin).href;

  const middleware = to.meta.middleware;
  const context = {
    to,
    from,
    next,
    store,
    path,
  };
  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

router.afterEach((to) => {
  // Use next tick to handle router history correctly
  Vue.nextTick(() => {
    document.title = to.meta.title || "Pulse";
  });
});

export default router;
