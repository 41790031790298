import _ from "lodash";

//List of all possible filters for the sources
export const emptyFilters = {
  id: null,
  name: null,
  accountId: null,
  accountName: null,
  enabled: null,
  channelUrl: null,
  tag: null,
  parsingGroup: null,
  parsingStatus: null,
  lastRun: null,
  projectId: null,
};
export const multipleFilters = [];

export const defaultFilters = _.merge({}, emptyFilters, {});

export default {
  sources: [],
  sourceItem: {
    name: null,
    tags: [],
    parsingGroup: null,
    enable: null,
  },
  count: 0,
  pagination: {
    page: 1,
    rowPerPage: 20,
  },
  sort: {
    sortBy: "isActive",
    desc: true,
  },
  filters: _.merge({}, defaultFilters),
};
