import _ from "lodash";
import { defaultFilters, emptyFilters } from "./state";

export const SET_PROJECTS_DATA = "SET_PROJECTS_DATA";
export const SET_PROJECT_DATA = "SET_PROJECT_DATA";
export const MUTATE_SELECTED_PAGE = "MUTATE_SELECTED_PAGE";
export const MUTATE_LIMIT = "MUTATE_LIMIT";
export const MUTATE_PROJECT_ID = "MUTATE_PROJECT_ID"
export const MUTATE_FILTERS = "SET_FILTERS";
export const MUTATE_DEFAULT_FILTERS = "SET_DEFAULT_FILTERS";
export const MUTATE_EMPTY_FILTERS = "SET_EMPTY_FILTERS";

export default {
  [SET_PROJECTS_DATA]: (state, projectData) => {
    state.projects = projectData.data;
    state.count = projectData.count;
  },
  [SET_PROJECT_DATA]: (state, projectData) => {
    state.projectItem = projectData.data;
    const item = state.projects.filter(
      (project) => project.id === projectData.data.id
    );
    const index = state.projects.indexOf(item[0]);
    if (~index) {
      state.projects[index] = projectData.data;
    }
  },
  [MUTATE_PROJECT_ID]: (state, projectId) => {
    state.projectId = projectId;
  },
  [MUTATE_SELECTED_PAGE]: () => {
    // state.pagination.page = page;
  },
  [MUTATE_LIMIT]: (state, limit) => {
    state.limit = limit;
  },
  [MUTATE_FILTERS]: (state, filters) => {
    if (!_.isEqual(_.merge({}, state.filters), filters)) {
      state.filters = filters;
    }
  },
  [MUTATE_DEFAULT_FILTERS]: (state) => {
    state.filters = _.merge({}, defaultFilters);
  },
  [MUTATE_EMPTY_FILTERS]: (state) => {
    state.filters = _.merge({}, emptyFilters);
  },
};
