import _ from "lodash";
import apiCall from "@/utils/api";

import {
  API_FETCH_USER_LOGS,
  API_FETCH_ACTION_GROUP_DICTIONARY,
} from "@/config/api.config";

import {
  MUTATE_DEFAULT_FILTERS,
  MUTATE_EMPTY_FILTERS,
  MUTATE_FILTERS,
  MUTATE_SELECTED_PAGE,
  SET_LOGS_DATA,
} from "./mutations";

export const FETCH_LOGS = "FETCH_LOGS";
export const FETCH_ACTION_GROUP_DICTIONARY = "FETCH_ACTION_GROUP_DICTIONARY";
export const SET_SELECTED_PAGE = "SET_SELECTED_PAGE";
export const SET_FILTERS = "SET_FILTERS";
export const SET_DEFAULT_FILTERS = "SET_DEFAULT_FILTERS";
export const SET_EMPTY_FILTERS = "SET_EMPTY_FILTERS";

export default {
  [FETCH_LOGS]({ commit, getters }) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_FETCH_USER_LOGS,
        params: {
          limit: getters.limit,
          offset: getters.offset,
          sortBy: getters.formattedSort,
          search: _.merge({}, getters.formattedFilters),
        },
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && !data.error) {
          commit(SET_LOGS_DATA, data);
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  },
  [FETCH_ACTION_GROUP_DICTIONARY]() {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_FETCH_ACTION_GROUP_DICTIONARY,
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && !data.error) {
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  },
  [SET_SELECTED_PAGE]({ commit }, page) {
    commit(MUTATE_SELECTED_PAGE, page);
  },
  [SET_FILTERS]({ commit }, data) {
    commit(MUTATE_FILTERS, data);
  },
  [SET_DEFAULT_FILTERS]({ commit }, data) {
    commit(MUTATE_DEFAULT_FILTERS, data);
  },
  [SET_EMPTY_FILTERS]({ commit }, data) {
    commit(MUTATE_EMPTY_FILTERS, data);
  },
};
