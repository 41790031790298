import apiCall from "@/utils/api";

import {
  API_CREATE_PROXY_PACKAGE,
  API_DELETE_PROXY_PACKAGE,
  API_FETCH_PROXY_PACKAGE,
  API_FETCH_PROXY_PACKAGES,
  API_FETCH_PROXY_PACKAGES_FILE,
  API_UPDATE_PROXY_PACKAGE,
} from "@/config/api.config";

import {
  MUTATE_DEFAULT_FILTERS,
  MUTATE_EMPTY_FILTERS,
  MUTATE_FILTERS,
  MUTATE_SELECTED_PAGE,
  PUSH_PROXY_PACKAGE_DATA,
  REMOVE_PROXY_PACKAGE_DATA,
  SET_PROXY_PACKAGE_DATA,
  SET_PROXY_PACKAGES_DATA,
} from "./mutations";
import downloadFile from "@/utils/file";

export const FETCH_PROXY_PACKAGES = "FETCH_PROXY_PACKAGES";
export const FETCH_PROXY_PACKAGES_FILE = "FETCH_PROXY_PACKAGES_FILE";
export const FETCH_PROXY_PACKAGE = "FETCH_PROXY_PACKAGE";
export const CREATE_PROXY_PACKAGE = "CREATE_PROXY_PACKAGE";
export const DELETE_PROXY_PACKAGE = "DELETE_PROXY_PACKAGE";
export const UPDATE_PROXY_PACKAGE = "UPDATE_PROXY_PACKAGE";
export const SET_SELECTED_PAGE = "SET_SELECTED_PAGE";
export const SET_FILTERS = "SET_FILTERS";
export const SET_DEFAULT_FILTERS = "SET_DEFAULT_FILTERS";
export const SET_EMPTY_FILTERS = "SET_EMPTY_FILTERS";

export default {
  [FETCH_PROXY_PACKAGES]({ commit, getters }) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_FETCH_PROXY_PACKAGES,
        params: {
          limit: getters.limit,
          offset: getters.offset,
          sortBy: getters.formattedSort,
          search: getters.formattedFilters,
        },
      }).then((response) => {
        try {
          let { status, data } = response;
          if (status === 200 && !data.error) {
            commit(SET_PROXY_PACKAGES_DATA, data);
            resolve(data);
          } else {
            reject(data);
          }
        } catch (e) {
          // eslint-disable-next-line no-empty
        }
      });
    });
  },
  [FETCH_PROXY_PACKAGES_FILE]({ getters }) {
    return new Promise((resolve, reject) => {
      return downloadFile({
        method: API_FETCH_PROXY_PACKAGES_FILE,
        params: {
          sortBy: getters.formattedSort,
          search: getters.formattedFilters,
        },
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && !data.error) {
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  },
  [FETCH_PROXY_PACKAGE]({ commit }, { itemId, how }) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_FETCH_PROXY_PACKAGE,
        parts: { id: itemId },
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && !data.error) {
          if (how === "push") {
            commit(PUSH_PROXY_PACKAGE_DATA, data);
          } else {
            commit(SET_PROXY_PACKAGE_DATA, data);
          }
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  },
  [CREATE_PROXY_PACKAGE](context, payload) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_CREATE_PROXY_PACKAGE,
        data: payload,
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && !data.error) {
          resolve(data.data);
        } else {
          reject(data.details);
        }
      });
    });
  },
  [DELETE_PROXY_PACKAGE]({ commit }, proxyPackage) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_DELETE_PROXY_PACKAGE,
        parts: { id: proxyPackage.id },
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && !data.error) {
          commit(REMOVE_PROXY_PACKAGE_DATA, proxyPackage);
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  },
  [UPDATE_PROXY_PACKAGE](context, payload) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_UPDATE_PROXY_PACKAGE,
        data: payload.editedItem,
        parts: { id: payload.id },
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && !data.error) {
          resolve(data);
        } else {
          reject(data.details);
        }
      });
    });
  },
  [SET_SELECTED_PAGE]({ commit }, page) {
    commit(MUTATE_SELECTED_PAGE, page);
  },
  [SET_FILTERS]({ commit }, data) {
    commit(MUTATE_FILTERS, data);
  },
  [SET_DEFAULT_FILTERS]({ commit }, data) {
    commit(MUTATE_DEFAULT_FILTERS, data);
  },
  [SET_EMPTY_FILTERS]({ commit }, data) {
    commit(MUTATE_EMPTY_FILTERS, data);
  },
};
