import _ from "lodash";

export const emptyFilters = {
  name: null,
};

export const multipleFilters = [];

export const defaultFilters = _.merge({}, emptyFilters, {});

export default {
  tags: [],
  tagNames: [],
  customTagNames: [],
  count: 0,
  pagination: {
    page: 1,
    rowPerPage: 20,
  },
  sort: {
    sortBy: "id",
    desc: false,
  },
  filters: _.merge({}, defaultFilters),
};
