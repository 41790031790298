import _ from "lodash";

export const emptyFilters = {
  name: null,
  enabled: null,
  status: null,
};

export const multipleFilters = [];

export const defaultFilters = _.merge({}, emptyFilters, {});

export default {
  proxies: [],
  packageId: null,
  count: 0,
  pagination: {
    page: 1,
    rowPerPage: 20,
  },
  sort: {
    sortBy: "active",
    desc: true,
  },
  filters: _.merge({}, defaultFilters),
};
