import _ from "lodash";

export const emptyFilters = {
  channelId: null,
  channelName: null,
  parsingType: null,
  parsingStatus: null,
  errorCode: null,
  parsingDate: null,
  proxyAddress: null,
};
export const multipleFilters = [];
export const defaultFilters = _.merge({}, emptyFilters, {});

export default {
  logs: [],
  count: 0,
  pagination: {
    page: 1,
    rowPerPage: 20,
  },
  sort: {
    sortBy: "parsingDate",
    desc: true,
  },
  filters: _.merge({}, defaultFilters),
};
