import _ from "lodash";

export const emptyFilters = {
  id: null,
  name: null,
  enabled: null,
  type: null,
  url: null,
  parsingStatus: null,
  lastParsed: null,
  lastArticle: null,
};

export const multipleFilters = [];

export const defaultFilters = _.merge({}, emptyFilters, {});

export default {
  channels: [],
  count: 0,
  pagination: {
    page: 1,
    rowPerPage: 20,
  },
  sort: {
    sortBy: "id",
    desc: false,
  },
  filters: _.merge({}, defaultFilters),
};
