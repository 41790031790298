import { SET_USER_DATA } from "./mutations";
import { API_USER_IDENTITY } from "@/config/api.config";
import apiCall from "@/utils/api";

export const FETCH_PROFILE = "FETCH_PROFILE";

export default {
  [FETCH_PROFILE]({ commit }) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_USER_IDENTITY,
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && data) {
          commit(SET_USER_DATA, data.user);
          resolve();
        } else {
          console.log(response);
          reject();
        }
      });
    });
  },
  hasAccess({ getters }, resources) {
    if (getters.currentUser?.role === "superadmin") {
      return true;
    }
    let hasAccess = true;
    resources.forEach(function (resource) {
      if (!getters.currentUser["permissions"][resource]) {
        hasAccess = false;
      }
    });
    return hasAccess;
  },
};
