import apiCall from "@/utils/api";

import {
  API_CREATE_TAG,
  API_DELETE_TAG,
  API_FETCH_TAGS_FILE,
  API_FETCH_TAG,
  API_FETCH_TAGS,
  API_UPDATE_TAG,
  API_FETCH_TAG_NAMES,
  API_FETCH_CUSTOM_TAG_NAMES,
} from "@/config/api.config";

import {
  MUTATE_DEFAULT_FILTERS,
  MUTATE_EMPTY_FILTERS,
  MUTATE_FILTERS,
  MUTATE_SELECTED_PAGE,
  PUSH_TAG_DATA,
  REMOVE_TAG_DATA,
  SET_CUSTOM_TAG_NAMES_DATA,
  SET_TAG_DATA,
  SET_TAG_NAMES_DATA,
  SET_TAGS_DATA,
} from "./mutations";
import downloadFile from "@/utils/file";

export const FETCH_TAG_NAMES = "FETCH_TAG_NAMES";
export const FETCH_CUSTOM_TAG_NAMES = "FETCH_CUSTOM_TAG_NAMES";
export const FETCH_TAGS = "FETCH_TAGS";
export const FETCH_TAGS_FILE = "FETCH_TAGS_FILE";
export const FETCH_TAG = "FETCH_TAG";
export const CREATE_TAG = "CREATE_TAG";
export const DELETE_TAG = "DELETE_TAG";
export const UPDATE_TAG = "UPDATE_TAG";
export const SET_SELECTED_PAGE = "SET_SELECTED_PAGE";
export const SET_FILTERS = "SET_FILTERS";
export const SET_DEFAULT_FILTERS = "SET_DEFAULT_FILTERS";
export const SET_EMPTY_FILTERS = "SET_EMPTY_FILTERS";

export default {
  [FETCH_TAG_NAMES]({ commit }) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_FETCH_TAG_NAMES,
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && !data.error) {
          commit(SET_TAG_NAMES_DATA, data.data);
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  },
  [FETCH_CUSTOM_TAG_NAMES]({ commit }) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_FETCH_CUSTOM_TAG_NAMES,
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && !data.error) {
          commit(SET_CUSTOM_TAG_NAMES_DATA, data.data);
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  },
  [FETCH_TAGS]({ commit, getters }) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_FETCH_TAGS,
        params: {
          limit: getters.limit,
          offset: getters.offset,
          sortBy: getters.formattedSort,
          search: getters.formattedFilters,
        },
      }).then((response) => {
        try {
          let { status, data } = response;
          if (status === 200 && !data.error) {
            commit(SET_TAGS_DATA, data);
            resolve(data);
          } else {
            reject(data);
          }
        } catch (e) {
          // eslint-disable-next-line no-empty
        }
      });
    });
  },
  [FETCH_TAGS_FILE]({ getters }) {
    return new Promise((resolve, reject) => {
      return downloadFile({
        method: API_FETCH_TAGS_FILE,
        params: {
          sortBy: getters.formattedSort,
          search: getters.formattedFilters,
        },
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && !data.error) {
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  },
  [FETCH_TAG]({ commit }, { itemId, how }) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_FETCH_TAG,
        parts: { id: itemId },
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && !data.error) {
          if (how === "push") {
            commit(PUSH_TAG_DATA, data);
          } else {
            commit(SET_TAG_DATA, data);
          }
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  },
  [CREATE_TAG](context, payload) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_CREATE_TAG,
        data: payload,
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && !data.error) {
          resolve(data.data);
        } else {
          reject(data.details);
        }
      });
    });
  },
  [DELETE_TAG]({ commit }, tag) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_DELETE_TAG,
        parts: { id: tag.id },
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && !data.error) {
          commit(REMOVE_TAG_DATA, tag);
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  },
  [UPDATE_TAG](context, payload) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_UPDATE_TAG,
        data: payload.editedItem,
        parts: { id: payload.id },
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && !data.error) {
          resolve(data);
        } else {
          reject(data.details);
        }
      });
    });
  },
  [SET_SELECTED_PAGE]({ commit }, page) {
    commit(MUTATE_SELECTED_PAGE, page);
  },
  [SET_FILTERS]({ commit }, data) {
    commit(MUTATE_FILTERS, data);
  },
  [SET_DEFAULT_FILTERS]({ commit }, data) {
    commit(MUTATE_DEFAULT_FILTERS, data);
  },
  [SET_EMPTY_FILTERS]({ commit }, data) {
    commit(MUTATE_EMPTY_FILTERS, data);
  },
};
