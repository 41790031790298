import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import VueHtmlToPaper from "vue-html-to-paper";

import vuetify from "./plugins/vuetify";

import Qs from "qs";

import VCalendar from 'v-calendar';

// Format nested params correctly
axios.interceptors.request.use((config) => {
  config.paramsSerializer = (params) => {
    // Qs is not included in the Axios package
    return Qs.stringify(params, {
      arrayFormat: "brackets",
      encode: false,
    });
  };

  return config;
});

Vue.config.productionTip = false;
Vue.use(VueAxios, axios);
Vue.use(VueHtmlToPaper);
Vue.use(VCalendar, {componentPrefix: 'vc'});
Vue.config.silent = true;

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
