import _ from "lodash";

export const emptyFilters = {
  name: "",
  email: "",
  currentPackage: null,
  nextPackage: null,
};

export const multipleFilters = [];

export const defaultFilters = _.merge({}, emptyFilters, {});

export default {
  packageDictionary: [],
  accounts: [],
  count: 0,
  pagination: {
    page: 1,
    rowPerPage: 20,
  },
  sort: {
    sortBy: "lastLogin",
    desc: true,
  },
  filters: _.merge({}, defaultFilters),
};
