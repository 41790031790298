import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("user", ["currentUser"]),
  },
  methods: {
    hasAccess: function (resources) {
      let vm = this;
      if (vm.currentUser?.role === "superadmin") {
        return true;
      }
      let hasAccess = true;
      resources.forEach(function (resource) {
        if (!vm.currentUser["permissions"][resource]) {
          hasAccess = false;
        }
      });
      return hasAccess;
    },
  },
};
