import apiCall from "@/utils/api";

import {
  API_FETCH_PROJECTS,
  API_FETCH_PROJECT,
  API_UPDATE_PROJECT,
} from "@/config/api.config";

import {
  SET_PROJECTS_DATA,
  SET_PROJECT_DATA,
  MUTATE_FILTERS,
  MUTATE_EMPTY_FILTERS,
  MUTATE_DEFAULT_FILTERS,
  MUTATE_SELECTED_PAGE,
  MUTATE_LIMIT,
  MUTATE_PROJECT_ID,
} from "./mutations";

export const FETCH_PROJECTS = "FETCH_PROJECTS";
export const FETCH_PROJECT = "FETCH_PROJECT";
export const UPDATE_PROJECT = "UPDATE_PROJECT";

export const SET_SELECTED_PAGE = "SET_SELECTED_PAGE";
export const SET_LIMIT = "SET_LIMIT";
export const SET_PROJECT_ID = "SET_PROJECT_ID";

export const SET_FILTERS = "SET_FILTERS";
export const SET_DEFAULT_FILTERS = "SET_DEFAULT_FILTERS";
export const SET_EMPTY_FILTERS = "SET_EMPTY_FILTERS";
export const SET_EMPTY_PROJECT = "SET_EMPTY_PROJECT";

export default {
  [FETCH_PROJECTS]({ commit, getters }) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_FETCH_PROJECTS,
        params: {
          limit: getters.limit,
          offset: getters.offset,
          sortBy: getters.formattedSort,
          search: getters.formattedFilters,
        },
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && !data.error) {
          commit(SET_PROJECTS_DATA, data);
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  },

  [FETCH_PROJECT]({ commit }, id) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_FETCH_PROJECT,
        parts: { id: id },
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && !data.error) {
          commit(SET_PROJECT_DATA, data);
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  },
  [UPDATE_PROJECT](context, payload) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_UPDATE_PROJECT,
        data: payload.editedItem,
        parts: { id: payload.id },
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && data) {
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  },
  [SET_PROJECT_ID]({ commit }, data) {
    commit(MUTATE_PROJECT_ID, data);
  },
  [SET_SELECTED_PAGE]({ commit }, page) {
    commit(MUTATE_SELECTED_PAGE, page);
  },
  [SET_LIMIT]({ commit }, limit) {
    commit(MUTATE_LIMIT, limit);
  },
  [SET_FILTERS]({ commit }, data) {
    commit(MUTATE_FILTERS, data);
  },
  [SET_DEFAULT_FILTERS]({ commit }, data) {
    commit(MUTATE_DEFAULT_FILTERS, data);
  },
  [SET_EMPTY_FILTERS]({ commit }, data) {
    commit(MUTATE_EMPTY_FILTERS, data);
  },
  [SET_EMPTY_PROJECT] ({ commit }) {
    commit(SET_PROJECT_DATA, {});
  }
};
