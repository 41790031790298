<template>
  <component :is="layout"></component>
</template>

<script>
import DefaultLayout from "@/views/layout/DefaultLayout";
import BlankLayout from "@/views/layout/BlankLayout";
import _ from "lodash";

export default {
  name: "App",
  components: {
    Default: DefaultLayout,
    Blank: BlankLayout,
  },
  data() {
    return {
      currentUser: null,
    };
  },
  computed: {
    layout() {
      return _.get(this.$route.meta, "layout", "default");
    },
  },
  mounted: function () {
    //если есть токен, вызвать dispatch(`user/${FETCH_PROFILE}`)
    if (this.$store.getters["auth/isAuthenticated"]) {
      this.$store.dispatch("user/FETCH_PROFILE");
    }
  },
};
</script>
