import Vue from "vue";
import _ from "lodash";

import store from "@/store";
import router from "@/router";

import { apiRoutes } from "@/config/api.config";
import { AUTH_LOGOUT } from "../store/modules/auth/actions";

const downloadFile = function ({ method, data, params, parts, headers = {} }) {
  return new Promise((resolve, reject) => {
    try {
      let action = _.get(apiRoutes.methods, [method]);
      let url = _.template(apiRoutes.host + action.url);
      let token = store.getters["auth/accessToken"];
      if (token) {
        headers["Authorization"] = `Bearer ${token}`;
      }

      Vue.axios({
        url: url(parts),
        method: action.httpMethod,
        data,
        params,
        headers,
        responseType: "blob", // important
      }).then(
        (response) => {
          let fileURL = window.URL.createObjectURL(new Blob([response.data]));
          let fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", response.statusText);
          document.body.appendChild(fileLink);

          fileLink.click();
          fileLink.remove();
        },
        (error) => {
          if (error.response && error.response.status === 401) {
            store.dispatch(`auth/${AUTH_LOGOUT}`);
            router.go({ name: "SignInPage" });
          } else {
            resolve(error.response);
          }
        }
      );
    } catch (err) {
      reject(new Error(err));
    }
  });
};

export default downloadFile;
