import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";
import user from "./modules/user";
import sources from "./modules/source";
import customSources from "./modules/customSource";
import channels from "./modules/channel";
import tags from "./modules/tag";
import logs from "./modules/logs";
import proxy from "./modules/proxy";
import account from "./modules/account";
import proxyPackages from "./modules/proxyPackage";
import subscriptionPackage from "./modules/subscriptionPackage";
import parsingGroup from "./modules/parsingGroup";
import usersLog from "./modules/usersLog";
import dashboard from "./modules/dashboard";
import admin from "./modules/admin";
import project from "./modules/project";
import projectsLog from "./modules/projectsLog";
import projectsManualArticles from "./modules/projectsManualArticles";
import projectTasks from "./modules/projectTasks";

import persistentStorage from "./modules/persistentStorage";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    user,
    source: sources,
    customSource: customSources,
    channels,
    tag: tags,
    log: logs,
    proxyPackage: proxyPackages,
    proxy,
    subscriptionPackage,
    parsingGroup,
    account,
    dashboard,
    persistentStorage,
    usersLog,
    admin,
    project,
    projectsLog,
    projectsManualArticles,
    projectTasks,
  },
  plugins: [
    createPersistedState({ paths: ["auth", "persistentStorage", "user"] }),
  ],
});
