import _ from "lodash";
import apiCall from "@/utils/api";
import { API_FETCH_SOURCE_LOG } from "@/config/api.config";
import { SET_SOURCE_LOG_DATA } from "./mutations";
import {
  MUTATE_DEFAULT_FILTERS,
  MUTATE_EMPTY_FILTERS,
  MUTATE_FILTERS,
} from "./mutations";
import { API_FETCH_SOURCE_LOG_FILE } from "../../../config/api.config";
import downloadFile from "../../../utils/file";

export const FETCH_SOURCE_LOG = "FETCH_SOURCE_LOG";
export const FETCH_SOURCE_LOG_FILE = "FETCH_SOURCE_LOG_FILE";
export const SET_FILTERS = "SET_FILTERS";
export const SET_DEFAULT_FILTERS = "SET_DEFAULT_FILTERS";
export const SET_EMPTY_FILTERS = "SET_EMPTY_FILTERS";

export default {
  [FETCH_SOURCE_LOG]({ commit, getters }, { sourceId }) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_FETCH_SOURCE_LOG,
        params: {
          offset: getters.offset,
          limit: getters.limit,
          sortBy: getters.formattedSort,
          search: _.merge({ sourceId }, getters.formattedFilters),
        },
        parts: { id: sourceId },
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && !data.error) {
          commit(SET_SOURCE_LOG_DATA, data);
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  },
  [FETCH_SOURCE_LOG_FILE]({ getters }, { sourceId }) {
    return new Promise((resolve, reject) => {
      return downloadFile({
        method: API_FETCH_SOURCE_LOG_FILE,
        params: {
          sortBy: getters.formattedSort,
          search: _.merge({ sourceId }, getters.formattedFilters),
        },
        parts: { id: sourceId },
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && !data.error) {
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  },
  [SET_FILTERS]({ commit }, data) {
    commit(MUTATE_FILTERS, data);
  },
  [SET_DEFAULT_FILTERS]({ commit }, data) {
    commit(MUTATE_DEFAULT_FILTERS, data);
  },
  [SET_EMPTY_FILTERS]({ commit }, data) {
    commit(MUTATE_EMPTY_FILTERS, data);
  },
};
