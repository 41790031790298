import _ from "lodash";

export const emptyFilters = {
  actionType: null,
  status: null,
};

export const multipleFilters = [];

export const defaultFilters = _.merge({}, emptyFilters, {});

export default {
  items: [],
  count: 0,
  pagination: {
    page: 1,
    rowPerPage: 20,
  },
  sort: {
    sortBy: "createdAt",
    desc: true,
  },
  filters: _.merge({}, defaultFilters),
};
