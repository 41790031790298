import apiCall from "@/utils/api";
import {
  API_FETCH_SUBSCRIPTION_PACKAGES,
  API_DELETE_SUBSCRIPTION_PACKAGE,
  API_CREATE_SUBSCRIPTION_PACKAGE,
  API_UPDATE_SUBSCRIPTION_PACKAGE,
} from "@/config/api.config";

import { SET_PACKAGES, MUTATE_SELECTED_PAGE, MUTATE_LIMIT } from "./mutations";

export const FETCH_PACKAGES = "FETCH_SOURCES";
export const DELETE_PACKAGE = "DELETE_PACKAGE";
export const SAVE_PACKAGE = "SAVE_PACKAGE";

export const SET_SELECTED_PAGE = "SET_SELECTED_PAGE";
export const SET_LIMIT = "SET_LIMIT";

export default {
  [FETCH_PACKAGES]({ commit, getters }) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_FETCH_SUBSCRIPTION_PACKAGES,
        params: {
          limit: getters.limit,
          offset: getters.offset,
        },
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && !data.error) {
          commit(SET_PACKAGES, data);
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  },
  [DELETE_PACKAGE]: (context, { id }) => {
    return new Promise((resolve, reject) => {
      //Return async api handler
      apiCall({
        method: API_DELETE_SUBSCRIPTION_PACKAGE,
        parts: {
          packageId: id,
        },
      })
        .then((response) => {
          let payload = response.data;
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [SAVE_PACKAGE]: (context, { data, id }) => {
    return new Promise((resolve, reject) => {
      //Return async api handler
      apiCall({
        method: id
          ? API_UPDATE_SUBSCRIPTION_PACKAGE
          : API_CREATE_SUBSCRIPTION_PACKAGE,
        data,
        parts: {
          packageId: id,
        },
      })
        .then((response) => {
          let payload = response.data;
          //Provide the control to the next callback
          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  [SET_SELECTED_PAGE]({ commit }, page) {
    commit(MUTATE_SELECTED_PAGE, page);
  },
  [SET_LIMIT]({ commit }, limit) {
    commit(MUTATE_LIMIT, limit);
  },
};
