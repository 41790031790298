export default {
  packageList: (state) => {
    return state.packages;
  },
  totalCount: (state) => {
    return state.count;
  },
  currentPage: (state) => {
    return state.pagination.page;
  },
  pageCount: (state) => {
    return Math.ceil(state.count / state.pagination.rowPerPage);
  },
  limit: (state) => {
    return state.pagination.rowPerPage;
  },
  offset: (state) => {
    return (state.pagination.page - 1) * state.pagination.rowPerPage;
  },
};
