import _ from "lodash";
import { defaultFilters, emptyFilters } from "../channel/state";

export const SET_SOURCE_LOG_DATA = "SET_SOURCE_LOG_DATA";
export const MUTATE_FILTERS = "MUTATE_FILTERS";
export const MUTATE_DEFAULT_FILTERS = "MUTATE_DEFAULT_FILTERS";
export const MUTATE_EMPTY_FILTERS = "MUTATE_EMPTY_FILTERS";

export default {
  [SET_SOURCE_LOG_DATA]: (state, sourceLogData) => {
    state.logs = sourceLogData.data;
    state.count = sourceLogData.count;
  },
  [MUTATE_FILTERS]: (state, filters) => {
    if (!_.isEqual(_.merge({}, state.filters), filters)) {
      state.filters = filters;
    }
  },
  [MUTATE_DEFAULT_FILTERS]: (state) => {
    state.filters = _.merge({}, defaultFilters);
  },
  [MUTATE_EMPTY_FILTERS]: (state) => {
    state.filters = _.merge({}, emptyFilters);
  },
};
