import _ from "lodash";

import { defaultFilters, emptyFilters } from "./state";

export const SET_CHANNELS_DATA = "SET_CHANNELS_DATA";
export const SET_CHANNEL_DATA = "SET_CHANNEL_DATA";
export const PUSH_CHANNEL_DATA = "PUSH_CHANNEL_DATA";
export const MUTATE_SOURCE_ID = "MUTATE_SOURCE_ID";
export const MUTATE_SELECTED_PAGE = "MUTATE_SELECTED_PAGE";
export const MUTATE_FILTERS = "MUTATE_FILTERS";
export const MUTATE_DEFAULT_FILTERS = "MUTATE_DEFAULT_FILTERS";
export const MUTATE_EMPTY_FILTERS = "MUTATE_EMPTY_FILTERS";

export default {
  [SET_CHANNELS_DATA]: (state, channelsData) => {
    state.channels = channelsData.channels;
    state.count = channelsData.count;
  },
  [SET_CHANNEL_DATA]: (state, channelData) => {
    const item = state.channels.filter(
      (channel) => channel.id === channelData.data.id
    );
    const index = state.channels.indexOf(item[0]);
    if (~index) {
      state.channels.splice(index, 1, channelData.data);
    }
  },
  [PUSH_CHANNEL_DATA]: (state, channelData) => {
    const item = state.channels.filter(
      (channel) => channel.id === channelData.data.id
    );
    const index = state.channels.indexOf(item[0]);
    if (index === -1) {
      state.channels.unshift(channelData.data);
    }
  },
  [MUTATE_SOURCE_ID]: (state, sourceId) => {
    state.sourceId = sourceId;
  },
  [MUTATE_SELECTED_PAGE]: (state, page) => {
    state.pagination.page = page;
  },
  [MUTATE_FILTERS]: (state, filters) => {
    if (!_.isEqual(_.merge({}, state.filters), filters)) {
      state.filters = filters;
    }
  },
  [MUTATE_DEFAULT_FILTERS]: (state) => {
    state.filters = _.merge({}, defaultFilters);
  },
  [MUTATE_EMPTY_FILTERS]: (state) => {
    state.filters = _.merge({}, emptyFilters);
  },
};
