export default {
  selectedSourceFilters: (state) => {
    return state.selectedSourceFilters;
  },
  availableSourceFilters: function (state) {
    return state.availableSourceFilters;
  },
  selectedCustomSourceFilters: (state) => {
    return state.selectedCustomSourceFilters;
  },
  availableCustomSourceFilters: function (state) {
    return state.availableCustomSourceFilters;
  },
  selectedChannelFilters: (state) => {
    return state.selectedChannelFilters;
  },
  availableChannelFilters: function (state) {
    return state.availableChannelFilters;
  },
  selectedSourceLogFilters: (state) => {
    return state.selectedSourceLogFilters;
  },
  availableSourceLogFilters: function (state) {
    return state.availableSourceLogFilters;
  },
  selectedTagFilters: (state) => {
    return state.selectedTagFilters;
  },
  availableTagFilters: function (state) {
    return state.availableTagFilters;
  },
  selectedProxyPackageFilters: (state) => {
    return state.selectedProxyPackageFilters;
  },
  availableProxyPackageFilters: function (state) {
    return state.availableProxyPackageFilters;
  },
  selectedProxyFilters: (state) => {
    return state.selectedProxyFilters;
  },
  availableProxyFilters: function (state) {
    return state.availableProxyFilters;
  },
  selectedAccountFilters: (state) => {
    return state.selectedAccountFilters;
  },
  availableAccountFilters: function (state) {
    return state.availableAccountFilters;
  },
  selectedUserLogsFilters: (state) => {
    return state.selectedUserLogsFilters;
  },
  availableUserLogsFilters: function (state) {
    return state.availableUserLogsFilters;
  },
  selectedAdminFilters: (state) => {
    return state.selectedAdminFilters;
  },
  availableAdminFilters: function (state) {
    return state.availableAdminFilters;
  },
  selectedProjectFilters: (state) => {
    return state.selectedProjectFilters;
  },
  availableProjectFilters: function (state) {
    return state.availableProjectFilters;
  },
  selectedProjectLogsFilters: (state) => {
    return state.selectedProjectLogsFilters;
  },
  availableProjectLogsFilters: function (state) {
    return state.availableProjectLogsFilters;
  },
  selectedProjectManualArticlesFilters: (state) => {
    return state.selectedProjectManualArticlesFilters;
  },
  availableProjectManualArticlesFilters: function (state) {
    return state.availableProjectManualArticlesFilters;
  },
  selectedProjectTasksFilters: (state) => {
    return state.selectedProjectTasksFilters;
  },
  availableProjectTasksFilters: function (state) {
    return state.availableProjectTasksFilters;
  },
};
