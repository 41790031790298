import { render, staticRenderFns } from "./NavGroup.vue?vue&type=template&id=b8ff29ba&scoped=true"
import script from "./NavGroup.vue?vue&type=script&lang=js"
export * from "./NavGroup.vue?vue&type=script&lang=js"
import style0 from "./NavGroup.vue?vue&type=style&index=0&id=b8ff29ba&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8ff29ba",
  null
  
)

export default component.exports