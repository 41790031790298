export const LOGIN_START = "LOGIN_START";
export const LOGIN_STOP = "LOGIN_STOP";
export const LOGOUT = "LOGOUT";
export const UPDATE_ACCESS_TOKEN = "UPDATE_ACCESS_TOKEN";
export const UPDATE_REFRESH_TOKEN = "UPDATE_REFRESH_TOKEN";

export default {
  [LOGIN_START]: (state) => (state.loggingIn = true),
  [LOGIN_STOP]: (state, errorMessage) => {
    state.loggingIn = false;
    state.loginError = errorMessage;
  },
  [UPDATE_ACCESS_TOKEN]: (state, accessToken) => {
    state.accessToken = accessToken;
  },
  [UPDATE_REFRESH_TOKEN]: (state, refreshToken) => {
    state.refreshToken = refreshToken;
  },
  [LOGOUT]: (state) => {
    state.loggingIn = false;
    state.accessToken = null;
    state.refreshToken = null;
  },
};
