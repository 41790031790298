import guest from "./middleware/guest";
import user from "./middleware/user";
import acl from "./middleware/acl";

import {
  RESOURCE_ACCOUNTS,
  RESOURCE_ADMINS,
  RESOURCE_BALANCE_OPERATIONS,
  RESOURCE_CUSTOM_SOURCES,
  RESOURCE_PARSING_GROUPS,
  RESOURCE_PROXIES,
  RESOURCE_PROXY_PACKAGES,
  RESOURCE_SOURCES,
  RESOURCE_SUBSCRIPTION_PACKAGES,
  RESOURCE_TAGS,
  RESOURCE_USERS,
  RESOURCE_PROJECTS,
} from "@/store/modules/admin/getters";

export default [
  {
    path: "/",
    name: "HomePage",
    component: () => import("@/views/HomePage"),
    meta: {
      middleware: [user],
    },
  },
  {
    path: "/sign-in",
    name: "LoginPage",
    component: () => import("@/views/SignInPage"),
    meta: {
      middleware: [guest],
      layout: "blank",
    },
  },
  {
    path: "/sources",
    name: "SourceListPage",
    component: () => import("@/views/SourcesPage"),
    meta: {
      middleware: [user, acl(RESOURCE_SOURCES)],
    },
  },
  {
    path: "/source/:id/channels",
    name: "SourceItemPage",
    component: () => import("@/views/SourcePage"),
    meta: {
      middleware: [user, acl(RESOURCE_SOURCES)],
      nestedComponent: "ChannelList",
    },
    props: true,
  },
  {
    path: "/source/:id/logs",
    name: "SourceItemLogs",
    component: () => import("@/views/SourcePage"),
    meta: {
      middleware: [user, acl(RESOURCE_SOURCES)],
      nestedComponent: "SourceLogList",
    },
    props: true,
  },
  {
    path: "/sources/custom",
    name: "CustomSourceListPage",
    component: () => import("@/views/CustomSourcesPage"),
    meta: {
      middleware: [user, acl(RESOURCE_CUSTOM_SOURCES)],
    },
  },
  {
    path: "/source/custom/:id/channels",
    name: "CustomSourceItemPage",
    component: () => import("@/views/CustomSourcePage"),
    meta: {
      middleware: [user, acl(RESOURCE_CUSTOM_SOURCES)],
      nestedComponent: "ChannelList",
    },
    props: true,
  },
  {
    path: "/source/custom/:id/logs",
    name: "CustomSourceItemLogs",
    component: () => import("@/views/CustomSourcePage"),
    meta: {
      middleware: [user, acl(RESOURCE_CUSTOM_SOURCES)],
      nestedComponent: "SourceLogList",
    },
    props: true,
  },
  {
    path: "/parsing-groups",
    name: "ParsingGroups",
    component: () => import("@/views/ParsingGroupPage"),
    meta: {
      middleware: [user, acl(RESOURCE_PARSING_GROUPS)],
    },
    props: true,
  },
  {
    path: "/admin-management",
    name: "AdminManagement",
    component: () => import("@/views/AdminListPage"),
    meta: {
      middleware: [user, acl(RESOURCE_ADMINS)],
    },
    props: true,
  },
  {
    path: "/users_actions_log",
    name: "AccountLogsPage",
    component: () => import("@/views/AccountLogsPage"),
    meta: {
      middleware: [user, acl(RESOURCE_ACCOUNTS), acl(RESOURCE_USERS)],
    },
  },
  {
    path: "/accounts",
    name: "AccountListPage",
    component: () => import("@/views/AccountListPage"),
    meta: {
      middleware: [user, acl(RESOURCE_ACCOUNTS)],
    },
  },
  {
    path: "/account/:id/transactions",
    name: "AccountTransactionsPage",
    component: () => import("@/views/AccountTransactionsPage"),
    meta: {
      middleware: [
        user,
        acl(RESOURCE_ACCOUNTS),
        acl(RESOURCE_BALANCE_OPERATIONS),
      ],
    },
    props: true,
  },
  {
    path: "/account/:id/packages",
    name: "AccountPackagesPage",
    component: () => import("@/views/AccountPackagesPage"),
    meta: {
      middleware: [
        user,
        acl(RESOURCE_ACCOUNTS)
      ],
    },
    props: true,
  },
  {
    path: "/tags",
    name: "TagListPage",
    component: () => import("@/views/TagsPage"),
    meta: {
      middleware: [user, acl(RESOURCE_TAGS)],
    },
  },
  {
    path: "/subscriptions",
    name: "SubscriptionListPage",
    component: () => import("@/views/SubscriptionListPage"),
    meta: {
      middleware: [user, acl(RESOURCE_SUBSCRIPTION_PACKAGES)],
    },
  },
  {
    path: "/proxies",
    name: "ProxyPackageListPage",
    component: () => import("@/views/ProxyPackagesPage"),
    meta: {
      middleware: [user, acl(RESOURCE_PROXY_PACKAGES)],
    },
    props: true,
  },
  {
    path: "/proxies/:id",
    name: "ProxyListPage",
    component: () => import("@/views/ProxiesPage"),
    meta: {
      middleware: [user, acl(RESOURCE_PROXIES)],
    },
    props: true,
  },
  {
    path: "/projects",
    name: "ProjectsListPage",
    component: () => import("@/views/ProjectsPage"),
    meta: {
      middleware: [user, acl(RESOURCE_PROJECTS)],
    },
    props: true,
  },
  {
    path: "/project/:id/manual_publications",
    name: "ProjectManualArticlesPage",
    component: () => import("@/views/ProjectPage"),
    meta: {
      middleware: [user, acl(RESOURCE_PROJECTS)],
      nestedComponent: "ProjectManualArticlesPage",
    },
    props: true,
  },
  {
    path: "/project/:id/tasks",
    name: "ProjectTasksPage",
    component: () => import("@/views/ProjectPage"),
    meta: {
      middleware: [user, acl(RESOURCE_PROJECTS)],
      nestedComponent: "ProjectTasksPage",
    },
    props: true,
  },
  {
    path: "/project/:id/log",
    name: "ProjectItemLogs",
    component: () => import("@/views/ProjectPage"),
    meta: {
      middleware: [user, acl(RESOURCE_PROJECTS)],
      nestedComponent: "ProjectLogsPage",
    },
    props: true,
  },
  {
    path: "/not-allowed",
    name: "NotAllowed",
    component: () => import("@/views/NotAllowed"),
    meta: {
      middleware: [user],
    },
  },
  {
    path: "*",
    name: "NotFound",
    component: () => import("@/views/NotFound"),
    meta: {
      layout: "blank",
    },
  },
];
