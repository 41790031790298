import _ from "lodash";

import {
  defaultSourceFilters,
  defaultCustomSourceFilters,
  defaultChannelFilters,
  defaultSourceLogFilters,
  defaultTagFilters,
  defaultProxyPackageFilters,
  defaultProxyFilters,
  defaultAccountFilters,
  defaultUserLogsFilters,
  defaultAdminFilters,
  defaultProjectFilters,
  defaultProjectLogsFilters,
  defaultProjectManualArticlesFilters,
  defaultProjectTasksFilters,
} from "./state";

// SOURCE
export const MUTATE_ADD_SOURCE_FILTER = "MUTATE_ADD_SOURCE_FILTER";
export const MUTATE_REMOVE_SOURCE_FILTER = "MUTATE_REMOVE_SOURCE_FILTER";
export const MUTATE_SET_SOURCE_FILTERS = "MUTATE_SET_SOURCE_FILTERS";
export const MUTATE_SET_DEFAULT_SOURCE_FILTER =
  "MUTATE_SET_DEFAULT_SOURCE_FILTER";

// Custom SOURCE
export const MUTATE_ADD_CUSTOM_SOURCE_FILTER =
  "MUTATE_ADD_CUSTOM_SOURCE_FILTER";
export const MUTATE_REMOVE_CUSTOM_SOURCE_FILTER =
  "MUTATE_REMOVE_CUSTOM_SOURCE_FILTER";
export const MUTATE_SET_CUSTOM_SOURCE_FILTERS =
  "MUTATE_SET_CUSTOM_SOURCE_FILTERS";
export const MUTATE_SET_DEFAULT_CUSTOM_SOURCE_FILTER =
  "MUTATE_SET_DEFAULT_CUSTOM_SOURCE_FILTER";

// CHANNEL
export const MUTATE_ADD_CHANNEL_FILTER = "MUTATE_ADD_CHANNEL_FILTER";
export const MUTATE_REMOVE_CHANNEL_FILTER = "MUTATE_REMOVE_CHANNEL_FILTER";
export const MUTATE_SET_CHANNEL_FILTERS = "MUTATE_SET_CHANNEL_FILTERS";
export const MUTATE_SET_DEFAULT_CHANNEL_FILTER =
  "MUTATE_SET_DEFAULT_CHANNEL_FILTER";

// SOURCE LOG
export const MUTATE_ADD_SOURCE_LOG_FILTER = "MUTATE_ADD_SOURCE_LOG_FILTER";
export const MUTATE_REMOVE_SOURCE_LOG_FILTER =
  "MUTATE_REMOVE_SOURCE_LOG_FILTER";
export const MUTATE_SET_SOURCE_LOG_FILTERS = "MUTATE_SET_SOURCE_LOG_FILTERS";
export const MUTATE_SET_DEFAULT_SOURCE_LOG_FILTER =
  "MUTATE_SET_DEFAULT_SOURCE_LOG_FILTER";

// TAG
export const MUTATE_ADD_TAG_FILTER = "MUTATE_ADD_TAG_FILTER";
export const MUTATE_REMOVE_TAG_FILTER = "MUTATE_REMOVE_TAG_FILTER";
export const MUTATE_SET_TAG_FILTERS = "MUTATE_SET_TAG_FILTERS";
export const MUTATE_SET_DEFAULT_TAG_FILTER = "MUTATE_SET_DEFAULT_TAG_FILTER";

// PROXY PACKAGE
export const MUTATE_ADD_PROXY_PACKAGE_FILTER =
  "MUTATE_ADD_PROXY_PACKAGE_FILTER";
export const MUTATE_REMOVE_PROXY_PACKAGE_FILTER =
  "MUTATE_REMOVE_PROXY_PACKAGE_FILTER";
export const MUTATE_SET_PROXY_PACKAGE_FILTERS =
  "MUTATE_SET_PROXY_PACKAGE_FILTERS";
export const MUTATE_SET_DEFAULT_PROXY_PACKAGE_FILTER =
  "MUTATE_SET_DEFAULT_PROXY_PACKAGE_FILTER";

// PROXY
export const MUTATE_ADD_PROXY_FILTER = "MUTATE_ADD_PROXY_FILTER";
export const MUTATE_REMOVE_PROXY_FILTER = "MUTATE_REMOVE_PROXY_FILTER";
export const MUTATE_SET_PROXY_FILTERS = "MUTATE_SET_PROXY_FILTERS";
export const MUTATE_SET_DEFAULT_PROXY_FILTER =
  "MUTATE_SET_DEFAULT_PROXY_FILTER";

// Accounts
export const MUTATE_ADD_ACCOUNT_FILTER = "MUTATE_ADD_ACCOUNT_FILTER";
export const MUTATE_REMOVE_ACCOUNT_FILTER = "MUTATE_REMOVE_ACCOUNT_FILTER";
export const MUTATE_SET_ACCOUNT_FILTERS = "MUTATE_SET_ACCOUNT_FILTERS";
export const MUTATE_SET_DEFAULT_ACCOUNT_FILTER =
  "MUTATE_SET_DEFAULT_ACCOUNT_FILTER";

// User logs
export const MUTATE_ADD_USER_LOGS_FILTER = "MUTATE_ADD_USER_LOGS_FILTER";
export const MUTATE_REMOVE_USER_LOGS_FILTER = "MUTATE_REMOVE_USER_LOGS_FILTER";
export const MUTATE_SET_USER_LOGS_FILTERS = "MUTATE_SET_USER_LOGS_FILTERS";
export const MUTATE_SET_DEFAULT_USER_LOGS_FILTER =
  "MUTATE_SET_DEFAULT_USER_LOGS_FILTER";
// User logs
export const MUTATE_ADD_ADMIN_FILTER = "MUTATE_ADD_ADMIN_FILTER";
export const MUTATE_REMOVE_ADMIN_FILTER = "MUTATE_REMOVE_ADMIN_FILTER";
export const MUTATE_SET_ADMIN_FILTERS = "MUTATE_SET_ADMIN_FILTERS";
export const MUTATE_SET_DEFAULT_ADMIN_FILTER =
  "MUTATE_SET_DEFAULT_ADMIN_FILTER";

// Project
export const MUTATE_ADD_PROJECT_FILTER = "MUTATE_ADD_PROJECT_FILTER";
export const MUTATE_REMOVE_PROJECT_FILTER = "MUTATE_REMOVE_PROJECT_FILTER";
export const MUTATE_SET_PROJECT_FILTERS = "MUTATE_SET_PROJECT_FILTERS";
export const MUTATE_SET_DEFAULT_PROJECT_FILTER =
  "MUTATE_SET_DEFAULT_PROJECT_FILTER";

// Project logs
export const MUTATE_ADD_PROJECT_LOG_FILTER = "MUTATE_ADD_PROJECT_LOG_FILTER";
export const MUTATE_REMOVE_PROJECT_LOG_FILTER =
  "MUTATE_REMOVE_PROJECT_LOG_FILTER";
export const MUTATE_SET_PROJECT_LOG_FILTERS = "MUTATE_SET_PROJECT_LOG_FILTERS";
export const MUTATE_SET_DEFAULT_PROJECT_LOG_FILTER =
  "MUTATE_SET_DEFAULT_PROJECT_LOG_FILTER";

// Project Manual Articles
export const MUTATE_ADD_PROJECT_MANUAL_ARTICLES_FILTER =
  "MUTATE_ADD_PROJECT_MANUAL_ARTICLES_FILTER";
export const MUTATE_REMOVE_PROJECT_MANUAL_ARTICLES_FILTER =
  "MUTATE_REMOVE_PROJECT_MANUAL_ARTICLES_FILTER";
export const MUTATE_SET_PROJECT_MANUAL_ARTICLES_FILTERS =
  "MUTATE_SET_PROJECT_MANUAL_ARTICLES_FILTERS";
export const MUTATE_SET_DEFAULT_PROJECT_MANUAL_ARTICLES_FILTER =
  "MUTATE_SET_DEFAULT_PROJECT_MANUAL_ARTICLES_FILTER";

// Project tasks
export const MUTATE_ADD_PROJECT_TASKS_FILTER =
  "MUTATE_ADD_PROJECT_TASKS_FILTER";
export const MUTATE_REMOVE_PROJECT_TASKS_FILTER =
  "MUTATE_REMOVE_PROJECT_TASKS_FILTER";
export const MUTATE_SET_PROJECT_TASKS_FILTERS =
  "MUTATE_SET_PROJECT_TASKS_FILTERS";
export const MUTATE_SET_DEFAULT_PROJECT_TASKS_FILTER =
  "MUTATE_SET_DEFAULT_PROJECT_TASKS_FILTER";

export default {
  // Source
  [MUTATE_ADD_SOURCE_FILTER]: (state, filter) => {
    state.selectedSourceFilters.push(filter);
  },
  [MUTATE_REMOVE_SOURCE_FILTER]: (state, filter) => {
    const index = state.selectedSourceFilters.indexOf(filter);
    if (index > -1) {
      state.selectedSourceFilters.splice(index, 1);
    }
  },
  [MUTATE_SET_SOURCE_FILTERS]: (state, filters) => {
    state.selectedSourceFilters = filters;
  },
  [MUTATE_SET_DEFAULT_SOURCE_FILTER]: (state) => {
    state.selectedSourceFilters = _.merge([], defaultSourceFilters);
  },
  // Custom Source
  [MUTATE_ADD_CUSTOM_SOURCE_FILTER]: (state, filter) => {
    state.selectedCustomSourceFilters.push(filter);
  },
  [MUTATE_REMOVE_CUSTOM_SOURCE_FILTER]: (state, filter) => {
    const index = state.selectedCustomSourceFilters.indexOf(filter);
    if (index > -1) {
      state.selectedCustomSourceFilters.splice(index, 1);
    }
  },
  [MUTATE_SET_CUSTOM_SOURCE_FILTERS]: (state, filters) => {
    state.selectedCustomSourceFilters = filters;
  },
  [MUTATE_SET_DEFAULT_CUSTOM_SOURCE_FILTER]: (state) => {
    state.selectedCustomSourceFilters = _.merge([], defaultCustomSourceFilters);
  },
  // Channel
  [MUTATE_ADD_CHANNEL_FILTER]: (state, filter) => {
    const index = state.selectedChannelFilters.indexOf(filter);
    if (index === -1) {
      state.selectedChannelFilters.push(filter);
    }
  },
  [MUTATE_REMOVE_CHANNEL_FILTER]: (state, filter) => {
    const index = state.selectedChannelFilters.indexOf(filter);
    if (index > -1) {
      state.selectedChannelFilters.splice(index, 1);
    }
  },
  [MUTATE_SET_CHANNEL_FILTERS]: (state, filters) => {
    state.selectedChannelFilters = filters;
  },
  [MUTATE_SET_DEFAULT_CHANNEL_FILTER]: (state) => {
    state.selectedChannelFilters = _.merge([], defaultChannelFilters);
  },
  // Source log
  [MUTATE_ADD_SOURCE_LOG_FILTER]: (state, filter) => {
    state.selectedSourceLogFilters.push(filter);
  },
  [MUTATE_REMOVE_SOURCE_LOG_FILTER]: (state, filter) => {
    const index = state.selectedSourceLogFilters.indexOf(filter);
    if (index > -1) {
      state.selectedSourceLogFilters.splice(index, 1);
    }
  },
  [MUTATE_SET_SOURCE_LOG_FILTERS]: (state, filters) => {
    state.selectedSourceLogFilters = filters;
  },
  [MUTATE_SET_DEFAULT_SOURCE_LOG_FILTER]: (state) => {
    state.selectedSourceLogFilters = _.merge([], defaultSourceLogFilters);
  },
  // Tag
  [MUTATE_ADD_TAG_FILTER]: (state, filter) => {
    state.selectedTagFilters.push(filter);
  },
  [MUTATE_REMOVE_TAG_FILTER]: (state, filter) => {
    const index = state.selectedTagFilters.indexOf(filter);
    if (index > -1) {
      state.selectedTagFilters.splice(index, 1);
    }
  },
  [MUTATE_SET_TAG_FILTERS]: (state, filters) => {
    state.selectedTagFilters = filters;
  },
  [MUTATE_SET_DEFAULT_TAG_FILTER]: (state) => {
    state.selectedTagFilters = _.merge([], defaultTagFilters);
  },
  // Proxy package
  [MUTATE_ADD_PROXY_PACKAGE_FILTER]: (state, filter) => {
    state.selectedProxyPackageFilters.push(filter);
  },
  [MUTATE_REMOVE_PROXY_PACKAGE_FILTER]: (state, filter) => {
    const index = state.selectedProxyPackageFilters.indexOf(filter);
    if (index > -1) {
      state.selectedProxyPackageFilters.splice(index, 1);
    }
  },
  [MUTATE_SET_PROXY_PACKAGE_FILTERS]: (state, filters) => {
    state.selectedProxyPackageFilters = filters;
  },
  [MUTATE_SET_DEFAULT_PROXY_PACKAGE_FILTER]: (state) => {
    state.selectedProxyPackageFilters = _.merge([], defaultProxyPackageFilters);
  },
  // Proxy
  [MUTATE_ADD_PROXY_FILTER]: (state, filter) => {
    state.selectedProxyFilters.push(filter);
  },
  [MUTATE_REMOVE_PROXY_FILTER]: (state, filter) => {
    const index = state.selectedProxyFilters.indexOf(filter);
    if (index > -1) {
      state.selectedProxyFilters.splice(index, 1);
    }
  },
  [MUTATE_SET_PROXY_FILTERS]: (state, filters) => {
    state.selectedProxyFilters = filters;
  },
  [MUTATE_SET_DEFAULT_PROXY_FILTER]: (state) => {
    state.selectedProxyFilters = _.merge([], defaultProxyFilters);
  },
  // Accounts
  [MUTATE_ADD_ACCOUNT_FILTER]: (state, filter) => {
    state.selectedAccountFilters.push(filter);
  },
  [MUTATE_REMOVE_ACCOUNT_FILTER]: (state, filter) => {
    const index = state.selectedAccountFilters.indexOf(filter);
    if (index > -1) {
      state.selectedAccountFilters.splice(index, 1);
    }
  },
  [MUTATE_SET_ACCOUNT_FILTERS]: (state, filters) => {
    state.selectedAccountFilters = filters;
  },
  [MUTATE_SET_DEFAULT_ACCOUNT_FILTER]: (state) => {
    state.selectedAccountFilters = _.merge([], defaultAccountFilters);
  },
  // User logs
  [MUTATE_ADD_USER_LOGS_FILTER]: (state, filter) => {
    state.selectedUserLogsFilters.push(filter);
  },
  [MUTATE_REMOVE_USER_LOGS_FILTER]: (state, filter) => {
    const index = state.selectedUserLogsFilters.indexOf(filter);
    if (index > -1) {
      state.selectedUserLogsFilters.splice(index, 1);
    }
  },
  [MUTATE_SET_USER_LOGS_FILTERS]: (state, filters) => {
    state.selectedUserLogsFilters = filters;
  },
  [MUTATE_SET_DEFAULT_USER_LOGS_FILTER]: (state) => {
    state.selectedUserLogsFilters = _.merge([], defaultUserLogsFilters);
  },
  // Admin
  [MUTATE_ADD_ADMIN_FILTER]: (state, filter) => {
    state.selectedAdminFilters.push(filter);
  },
  [MUTATE_REMOVE_ADMIN_FILTER]: (state, filter) => {
    const index = state.selectedAdminFilters.indexOf(filter);
    if (index > -1) {
      state.selectedAdminFilters.splice(index, 1);
    }
  },
  [MUTATE_SET_ADMIN_FILTERS]: (state, filters) => {
    state.selectedAdminFilters = filters;
  },
  [MUTATE_SET_DEFAULT_ADMIN_FILTER]: (state) => {
    state.selectedAdminFilters = _.merge([], defaultAdminFilters);
  },
  // Project
  [MUTATE_ADD_PROJECT_FILTER]: (state, filter) => {
    state.selectedProjectFilters.push(filter);
  },
  [MUTATE_REMOVE_PROJECT_FILTER]: (state, filter) => {
    const index = state.selectedProjectFilters.indexOf(filter);
    if (index > -1) {
      state.selectedProjectFilters.splice(index, 1);
    }
  },
  [MUTATE_SET_PROJECT_FILTERS]: (state, filters) => {
    state.selectedProjectFilters = filters;
  },
  [MUTATE_SET_DEFAULT_PROJECT_FILTER]: (state) => {
    state.selectedProjectFilters = _.merge([], defaultProjectFilters);
  },
  // Project log
  [MUTATE_ADD_PROJECT_LOG_FILTER]: (state, filter) => {
    state.selectedProjectLogsFilters.push(filter);
  },
  [MUTATE_REMOVE_PROJECT_LOG_FILTER]: (state, filter) => {
    const index = state.selectedProjectLogsFilters.indexOf(filter);
    if (index > -1) {
      state.selectedProjectLogsFilters.splice(index, 1);
    }
  },
  [MUTATE_SET_PROJECT_LOG_FILTERS]: (state, filters) => {
    state.selectedProjectLogsFilters = filters;
  },
  [MUTATE_SET_DEFAULT_PROJECT_LOG_FILTER]: (state) => {
    state.selectedProjectLogsFilters = _.merge([], defaultProjectLogsFilters);
  },
  // Project manual articles
  [MUTATE_ADD_PROJECT_MANUAL_ARTICLES_FILTER]: (state, filter) => {
    state.selectedProjectManualArticlesFilters.push(filter);
  },
  [MUTATE_REMOVE_PROJECT_MANUAL_ARTICLES_FILTER]: (state, filter) => {
    const index = state.selectedProjectManualArticlesFilters.indexOf(filter);
    if (index > -1) {
      state.selectedProjectManualArticlesFilters.splice(index, 1);
    }
  },
  [MUTATE_SET_PROJECT_MANUAL_ARTICLES_FILTERS]: (state, filters) => {
    state.selectedProjectManualArticlesFilters = filters;
  },
  [MUTATE_SET_DEFAULT_PROJECT_MANUAL_ARTICLES_FILTER]: (state) => {
    state.selectedProjectManualArticlesFilters = _.merge(
      [],
      defaultProjectManualArticlesFilters
    );
  },
  // Project tasks
  [MUTATE_ADD_PROJECT_TASKS_FILTER]: (state, filter) => {
    state.selectedProjectTasksFilters.push(filter);
  },
  [MUTATE_REMOVE_PROJECT_TASKS_FILTER]: (state, filter) => {
    const index = state.selectedProjectTasksFilters.indexOf(filter);
    if (index > -1) {
      state.selectedProjectTasksFilters.splice(index, 1);
    }
  },
  [MUTATE_SET_PROJECT_TASKS_FILTERS]: (state, filters) => {
    state.selectedProjectTasksFilters = filters;
  },
  [MUTATE_SET_DEFAULT_PROJECT_TASKS_FILTER]: (state) => {
    state.selectedProjectTasksFilters = _.merge(
      [],
      defaultProjectTasksFilters
    );
  },
};
