import _ from "lodash";

export default {
  accountList: (state) => {
    return state.accounts;
  },
  packages: (state) => {
    return state.packageDictionary;
  },
  totalCount: (state) => {
    return state.count;
  },
  pageCount: (state) => {
    return Math.ceil(state.count / state.pagination.rowPerPage);
  },
  currentPage: (state) => {
    return state.pagination.page;
  },
  limit: (state) => {
    return state.pagination.rowPerPage;
  },
  offset: (state) => {
    return (state.pagination.page - 1) * state.pagination.rowPerPage;
  },
  headers: (state) => {
    return state.headers;
  },
  filters: (state) => {
    return state.filters;
  },
  formattedFilters: (state) => {
    let filters = {};
    _.forIn(state.filters, function (value, key) {
      if (value !== null && (!(value instanceof Array) || value.length)) {
        if (value.length - 1 && value instanceof Array) {
          filters[key] = value.join(",");
        } else {
          filters[key] = value;
        }
      }
    });
    return filters;
  },
  formattedSort: (state) => {
    let formatted = state.sort.sortBy;
    if (state.sort.desc) {
      return `-${formatted}`;
    }
    return formatted;
  },
  sortAttributes: (state) => {
    return state.sortAttributes;
  },
};
