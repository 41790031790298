import { SET_ARTICLES } from "./mutations";

import { API_GET_DASHBOARD } from "@/config/api.config";

export const GET_DASHBOARD = "GET_DASHBOARD";
import apiCall from "@/utils/api";

export default {
  [GET_DASHBOARD]({ commit }) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_GET_DASHBOARD,
      })
        .then((response) => {
          // update state
          let { status, data } = response;
          if (status === 200 && data) {
            // nullify state
            commit(SET_ARTICLES, data.articles);
            resolve();
          } else {
            reject();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  },
};
