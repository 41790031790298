import _ from "lodash";

import { defaultFilters, emptyFilters } from "./state";

export const SET_PROXIES_DATA = "SET_PROXIES_DATA";
export const SET_PROXY_DATA = "SET_PROXY_NAMES_DATA";
export const PUSH_PROXY_DATA = "PUSH_PROXY_DATA";
export const REMOVE_PROXY_DATA = "REMOVE_PROXY_DATA";

export const MUTATE_PROXY_PACKAGE_ID = "MUTATE_PROXY_PACKAGE_ID";
export const MUTATE_SELECTED_PAGE = "MUTATE_SELECTED_PAGE";
export const MUTATE_FILTERS = "MUTATE_FILTERS";
export const MUTATE_DEFAULT_FILTERS = "MUTATE_DEFAULT_FILTERS";
export const MUTATE_EMPTY_FILTERS = "MUTATE_EMPTY_FILTERS";

export default {
  [SET_PROXIES_DATA]: (state, proxiesData) => {
    state.proxies = proxiesData.data;
    state.count = proxiesData.count;
  },
  [SET_PROXY_DATA]: (state, proxyData) => {
    const item = state.proxies.filter(
      (proxy) => proxy.id === proxyData.data.id
    );
    const index = state.proxies.indexOf(item[0]);
    if (~index) {
      state.proxies.splice(index, 1, proxyData.data);
    }
  },
  [PUSH_PROXY_DATA]: (state, proxyData) => {
    ++state.count;
    const item = state.proxies.filter(
      (proxies) => proxies.id === proxyData.data.id
    );
    const index = state.proxies.indexOf(item[0]);
    if (index === -1) {
      state.proxies.unshift(proxyData.data);
    }
  },
  [REMOVE_PROXY_DATA]: (state, proxy) => {
    --state.count;
    const index = state.proxies.indexOf(proxy);
    if (index > -1) {
      state.proxies.splice(index, 1);
    }
  },
  [MUTATE_PROXY_PACKAGE_ID]: (state, packageId) => {
    state.packageId = packageId;
  },
  [MUTATE_SELECTED_PAGE]: (state, page) => {
    state.pagination.page = page;
  },
  [MUTATE_FILTERS]: (state, filters) => {
    if (!_.isEqual(_.merge({}, state.filters), filters)) {
      state.filters = filters;
    }
  },
  [MUTATE_DEFAULT_FILTERS]: (state) => {
    state.filters = _.merge({}, defaultFilters);
  },
  [MUTATE_EMPTY_FILTERS]: (state) => {
    state.filters = _.merge({}, emptyFilters);
  },
};
