import _ from "lodash";
import apiCall from "@/utils/api";

import {
  API_CREATE_PROXY,
  API_DELETE_PROXY,
  API_FETCH_PROXIES,
  API_FETCH_PROXIES_FILE,
  API_FETCH_PROXY,
  API_GET_PROXY_TEMPLATE,
  API_IMPORT_PROXY,
  API_UPDATE_PROXY,
} from "@/config/api.config";

import {
  MUTATE_DEFAULT_FILTERS,
  MUTATE_EMPTY_FILTERS,
  MUTATE_FILTERS,
  MUTATE_PROXY_PACKAGE_ID,
  MUTATE_SELECTED_PAGE,
  PUSH_PROXY_DATA,
  REMOVE_PROXY_DATA,
  SET_PROXIES_DATA,
  SET_PROXY_DATA,
} from "./mutations";
import downloadFile from "@/utils/file";

export const FETCH_PROXIES = "FETCH_PROXIES";
export const FETCH_PROXIES_FILE = "FETCH_PROXIES_FILE";
export const FETCH_PROXY_TEMPLATE = "FETCH_PROXY_TEMPLATE";
export const IMPORT_PROXY_FILE = "IMPORT_PROXY_FILE";
export const FETCH_PROXY = "FETCH_PROXY";
export const CREATE_PROXY = "CREATE_PROXY";
export const DELETE_PROXY = "DELETE_PROXY";
export const UPDATE_PROXY = "UPDATE_PROXY";
export const SET_PROXY_PACKAGE_ID = "SET_PROXY_PACKAGE_ID";
export const SET_SELECTED_PAGE = "SET_SELECTED_PAGE";
export const SET_FILTERS = "SET_FILTERS";
export const SET_DEFAULT_FILTERS = "SET_DEFAULT_FILTERS";
export const SET_EMPTY_FILTERS = "SET_EMPTY_FILTERS";

export default {
  [FETCH_PROXIES]({ commit, getters }, { packageId }) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_FETCH_PROXIES,
        params: {
          limit: getters.limit,
          offset: getters.offset,
          sortBy: getters.formattedSort,
          search: _.merge({ packageId }, getters.formattedFilters),
        },
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && !data.error) {
          commit(SET_PROXIES_DATA, data);
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  },
  [FETCH_PROXIES_FILE]({ getters }, { packageId }) {
    return new Promise((resolve, reject) => {
      return downloadFile({
        method: API_FETCH_PROXIES_FILE,
        params: {
          sortBy: getters.formattedSort,
          search: _.merge({ packageId }, getters.formattedFilters),
        },
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && !data.error) {
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  },
  [FETCH_PROXY_TEMPLATE]() {
    return new Promise((resolve, reject) => {
      return downloadFile({
        method: API_GET_PROXY_TEMPLATE,
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && !data.error) {
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  },
  [IMPORT_PROXY_FILE](context, { formData, packageId }) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_IMPORT_PROXY,
        data: formData,
        parts: { packageId: packageId },
        headers: { "Content-Type": "multipart/form-data" },
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && !data.error) {
          resolve(data.data);
        } else {
          reject(data.details);
        }
      });
    });
  },
  [FETCH_PROXY]({ commit }, { itemId, how }) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_FETCH_PROXY,
        parts: { id: itemId },
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && !data.error) {
          if (how === "push") {
            commit(PUSH_PROXY_DATA, data);
          } else {
            commit(SET_PROXY_DATA, data);
          }
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  },
  [CREATE_PROXY](context, payload) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_CREATE_PROXY,
        data: payload,
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && !data.error) {
          resolve(data.data);
        } else {
          reject(data.details);
        }
      });
    });
  },
  [DELETE_PROXY]({ commit }, proxy) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_DELETE_PROXY,
        parts: { id: proxy.id },
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && !data.error) {
          commit(REMOVE_PROXY_DATA, proxy);
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  },
  [UPDATE_PROXY](context, payload) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_UPDATE_PROXY,
        data: payload.editedItem,
        parts: { id: payload.id },
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && !data.error) {
          resolve(data);
        } else {
          reject(data.details);
        }
      });
    });
  },

  [SET_PROXY_PACKAGE_ID]({ commit }, data) {
    commit(MUTATE_PROXY_PACKAGE_ID, data);
  },
  [SET_SELECTED_PAGE]({ commit }, page) {
    commit(MUTATE_SELECTED_PAGE, page);
  },
  [SET_FILTERS]({ commit }, data) {
    commit(MUTATE_FILTERS, data);
  },
  [SET_DEFAULT_FILTERS]({ commit }, data) {
    commit(MUTATE_DEFAULT_FILTERS, data);
  },
  [SET_EMPTY_FILTERS]({ commit }, data) {
    commit(MUTATE_EMPTY_FILTERS, data);
  },
};
