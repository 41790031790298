import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi", // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    themes: {
      light: {
        primary: "#FE753B",
        secondary: "#FE753B",
        accent: "#FE753B",
        error: "#FE753B",
        info: "#FE753B",
        success: "#FE753B",
        warning: "#FE753B",
        outline: "#3a474c",
        greenLighten: "#E8F5E9",
        purpleLighten: "#EDE7F6",
      },
    },
  },
});
