import _ from "lodash";

import { defaultFilters, emptyFilters } from "./state";

export const SET_TAG_NAMES_DATA = "SET_TAG_NAMES_DATA";
export const SET_CUSTOM_TAG_NAMES_DATA = "SET_CUSTOM_TAG_NAMES_DATA";
export const SET_TAGS_DATA = "SET_TAGS_DATA";
export const SET_TAG_DATA = "SET_TAG_DATA";
export const PUSH_TAG_DATA = "PUSH_TAG_DATA";
export const REMOVE_TAG_DATA = "REMOVE_TAG_DATA";
export const MUTATE_SELECTED_PAGE = "MUTATE_SELECTED_PAGE";
export const MUTATE_FILTERS = "MUTATE_FILTERS";
export const MUTATE_DEFAULT_FILTERS = "MUTATE_DEFAULT_FILTERS";
export const MUTATE_EMPTY_FILTERS = "MUTATE_EMPTY_FILTERS";

export default {
  [SET_TAG_NAMES_DATA]: (state, tagNames) => {
    state.tagNames = tagNames;
  },
  [SET_CUSTOM_TAG_NAMES_DATA]: (state, tagNames) => {
    state.customTagNames = tagNames;
  },
  [SET_TAGS_DATA]: (state, tagsData) => {
    state.tags = tagsData.data;
    state.count = tagsData.count;
  },
  [SET_TAG_DATA]: (state, tagData) => {
    const item = state.tags.filter((tag) => tag.id === tagData.data.id);
    const index = state.tags.indexOf(item[0]);
    if (~index) {
      state.tags.splice(index, 1, tagData.data);
    }
  },
  [PUSH_TAG_DATA]: (state, tagData) => {
    ++state.count;
    const item = state.tags.filter((tags) => tags.id === tagData.data.id);
    const index = state.tags.indexOf(item[0]);
    if (index === -1) {
      state.tags.unshift(tagData.data);
    }
  },
  [REMOVE_TAG_DATA]: (state, tag) => {
    --state.count;
    const index = state.tags.indexOf(tag);
    if (index > -1) {
      state.tags.splice(index, 1);
    }
  },
  [MUTATE_SELECTED_PAGE]: (state, page) => {
    state.pagination.page = page;
  },
  [MUTATE_FILTERS]: (state, filters) => {
    if (!_.isEqual(_.merge({}, state.filters), filters)) {
      state.filters = filters;
    }
  },
  [MUTATE_DEFAULT_FILTERS]: (state) => {
    state.filters = _.merge({}, defaultFilters);
  },
  [MUTATE_EMPTY_FILTERS]: (state) => {
    state.filters = _.merge({}, emptyFilters);
  },
};
