export const SET_GROUPS = "SET_GROUPS";
export const SET_DICTIONARY = "SET_DICTIONARY";
export const MUTATE_SELECTED_PAGE = "MUTATE_SELECTED_PAGE";
export const MUTATE_LIMIT = "MUTATE_LIMIT";

export default {
  [SET_GROUPS]: (state, data) => {
    state.groups = data.data;
    state.count = data.count;
  },
  [SET_DICTIONARY]: (state, data) => {
    state.dictionary = data.data;
  },
  [MUTATE_SELECTED_PAGE]: () => {
    // state.pagination.page = page;
  },
  [MUTATE_LIMIT]: (state, limit) => {
    state.limit = limit;
  },
};
