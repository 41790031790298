import _ from "lodash";
import apiCall from "@/utils/api";

import {
  API_CREATE_PROJECT_TASK,
  API_FETCH_PROJECT_TASKS,
} from "@/config/api.config";

import {
  MUTATE_DEFAULT_FILTERS,
  MUTATE_EMPTY_FILTERS,
  MUTATE_FILTERS,
  MUTATE_SELECTED_PAGE,
  SET_PROJECT_TASKS_DATA,
} from "./mutations";

export const FETCH_PROJECT_TASKS = "FETCH_PROJECT_TASKS";
export const SET_SELECTED_PAGE = "SET_SELECTED_PAGE";
export const SET_FILTERS = "SET_FILTERS";
export const SET_DEFAULT_FILTERS = "SET_DEFAULT_FILTERS";
export const SET_EMPTY_FILTERS = "SET_EMPTY_FILTERS";
export const CREATE_TASK = "CREATE_TASK";

export default {
  [FETCH_PROJECT_TASKS]({ commit, getters }, id) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_FETCH_PROJECT_TASKS,
        parts: { id: id },
        params: {
          limit: getters.limit,
          offset: getters.offset,
          sortBy: getters.formattedSort,
          search: _.merge({}, getters.formattedFilters),
        },
      }).then((response) => {
        let { status, data } = response;
        if (status === 200 && !data.error) {
          commit(SET_PROJECT_TASKS_DATA, data);
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  },
  [CREATE_TASK](context, payload) {
    return new Promise((resolve, reject) => {
      return apiCall({
        method: API_CREATE_PROJECT_TASK,
        data: payload,
        parts: { id: payload.id },
      }).then((response) => {
        let { status, data } = response;
        if (status === 200) {
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  },
  [SET_SELECTED_PAGE]({ commit }, page) {
    commit(MUTATE_SELECTED_PAGE, page);
  },
  [SET_FILTERS]({ commit }, data) {
    commit(MUTATE_FILTERS, data);
  },
  [SET_DEFAULT_FILTERS]({ commit }, data) {
    commit(MUTATE_DEFAULT_FILTERS, data);
  },
  [SET_EMPTY_FILTERS]({ commit }, data) {
    commit(MUTATE_EMPTY_FILTERS, data);
  },
};
