import _ from "lodash";

import { defaultFilters, emptyFilters } from "./state";

export const SET_PROXY_PACKAGES_DATA = "SET_PROXY_PACKAGES_DATA";
export const SET_PROXY_PACKAGE_DATA = "SET_PROXY_PACKAGE_DATA";
export const PUSH_PROXY_PACKAGE_DATA = "PUSH_PROXY_PACKAGE_DATA";
export const REMOVE_PROXY_PACKAGE_DATA = "REMOVE_PROXY_PACKAGE_DATA";
export const MUTATE_SELECTED_PAGE = "MUTATE_SELECTED_PAGE";
export const MUTATE_FILTERS = "MUTATE_FILTERS";
export const MUTATE_DEFAULT_FILTERS = "MUTATE_DEFAULT_FILTERS";
export const MUTATE_EMPTY_FILTERS = "MUTATE_EMPTY_FILTERS";

export default {
  [SET_PROXY_PACKAGES_DATA]: (state, proxyPackagesData) => {
    state.proxyPackages = proxyPackagesData.data;
    state.count = proxyPackagesData.count;
  },
  [SET_PROXY_PACKAGE_DATA]: (state, proxyPackageData) => {
    state.packageItem = proxyPackageData.data;
    const item = state.proxyPackages.filter(
      (proxyPackage) => proxyPackage.id === proxyPackageData.data.id
    );
    const index = state.proxyPackages.indexOf(item[0]);
    if (~index) {
      state.proxyPackages.splice(index, 1, proxyPackageData.data);
    }
  },
  [PUSH_PROXY_PACKAGE_DATA]: (state, proxyPackageData) => {
    ++state.count;
    const item = state.proxyPackages.filter(
      (proxyPackages) => proxyPackages.id === proxyPackageData.data.id
    );
    const index = state.proxyPackages.indexOf(item[0]);
    if (index === -1) {
      state.proxyPackages.unshift(proxyPackageData.data);
    }
  },
  [REMOVE_PROXY_PACKAGE_DATA]: (state, proxyPackage) => {
    --state.count;
    const index = state.proxyPackages.indexOf(proxyPackage);
    if (index > -1) {
      state.proxyPackages.splice(index, 1);
    }
  },
  [MUTATE_SELECTED_PAGE]: (state, page) => {
    state.pagination.page = page;
  },
  [MUTATE_FILTERS]: (state, filters) => {
    if (!_.isEqual(_.merge({}, state.filters), filters)) {
      state.filters = filters;
    }
  },
  [MUTATE_DEFAULT_FILTERS]: (state) => {
    state.filters = _.merge({}, defaultFilters);
  },
  [MUTATE_EMPTY_FILTERS]: (state) => {
    state.filters = _.merge({}, emptyFilters);
  },
};
