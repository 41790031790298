import _ from "lodash";

//List of all possible filters for the sources
export const emptyFilters = {
  allowedResources: null,
  name: null,
  email: null,
  isActive: "true",
};

export const multipleFilters = [];

export const defaultFilters = _.merge({}, emptyFilters, {});

export default {
  items: [],
  aclResources: [],
  count: 0,
  pagination: {
    page: 1,
    rowPerPage: 20,
  },
  sort: {
    sortBy: "id",
    desc: true,
  },
  filters: _.merge({}, defaultFilters),
};
